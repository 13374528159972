import axios from "axios";
import authHeader from "./auth-header";

export const CreateOrder = async (order) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/paypal/create-order`, order, {headers: authHeader()});
};

export const Approve = async (orderId: string) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/paypal/approve`, {orderId}, {headers: authHeader()});
};
