export const PROFESSION = {
    0: "Seleziona",
    [-1]: "Personale non sanitario",
    [-2]: "OSS",
    1: "Medico chirurgo",
    2: "Odontoiatra",
    3: "Farmacista",
    4: "Veterinario",
    5: "Psicologo",
    6: "Biologo",
    7: "Chimico",
    8: "Fisico",
    9: "Assistente sanitario",
    10: "Dietista",
    11: "Educatore professionale",
    12: "Fisioterapista",
    13: "Igienista dentale",
    14: "Infermiere",
    15: "Infermiere pediatrico",
    16: "Logopedista",
    17: "Ortottista/assistente di oftalmologia",
    18: "Ostetrica/o",
    19: "Podologo",
    20: "Tecnico audiometrista",
    21: "Tecnico audioprotesista",
    22: "Tecnico della fisiopatologia cardiocircolatoria e perfusione cardiovascolare",
    23: "Tecnico della prevenzione nell'ambiente e nei luoghi di lavoro",
    24: "Tecnico della riabilitazione psichiatrica",
    25: "Tecnico di neurofisiopatologia",
    26: "Tecnico ortopedico",
    27: "Tecnico sanitario di radiologia medica",
    28: "Tecnico sanitario laboratorio biomedico",
    29: "Terapista della neuro e psicomotricità dell'età evolutiva",
    30: "Terapista occupazionale",
}

export const DISCIPLINE = {
    0: {0: "Seleziona"},
    [-1]: {[-1]: "Personale non sanitario"},
    1: {
        1: "Allergologia ed immunologia clinica",
        2: "Angiologia",
        3: "Cardiologia",
        4: "Dermatologia e venereologia",
        5: "Ematologia",
        6: "Endocrinologia",
        7: "Gastroenterologia",
        8: "Genetica medica",
        9: "Geriatria",
        10: "Malattie metaboliche e diabetologia",
        11: "Malattie dell'apparato respiratorio",
        12: "Malattie infettive",
        13: "Medicina e chirurgia di accettazione e di urgenza",
        14: "Medicina fisica e riabilitazione",
        15: "Medicina interna",
        16: "Medicina termale",
        17: "Medicina aeronautica e spaziale",
        18: "Medicina dello sport",
        19: "Nefrologia",
        20: "Neonatologia",
        21: "Neurologia",
        22: "Neuropsichiatria infantile",
        23: "Oncologia",
        24: "Pediatria",
        25: "Psichiatria",
        26: "Radioterapia",
        27: "Reumatologia",
        29: "Cardiochirurgia",
        30: "Chirurgia generale",
        31: "Chirurgia maxillo-facciale",
        32: "Chirurgia pediatrica",
        33: "Chirurgia plastica e ricostruttiva",
        34: "Chirurgia toracica",
        35: "Chirurgia vascolare",
        36: "Ginecologia e ostetricia",
        37: "Neurochirurgia",
        38: "Oftalmologia",
        39: "Ortopedia e traumatologia",
        40: "Otorinolaringoiatria",
        41: "Urologia",
        42: "Anatomia patologica",
        43: "Anestesia e rianimazione",
        44: "Biochimica clinica",
        45: "Farmacologia e tossicologia clinica",
        46: "Laboratorio di genetica medica",
        47: "Medicina trasfusionale",
        48: "Medicina legale",
        49: "Medicina nucleare",
        50: "Microbiologia e virologia",
        51: "Neurofisiopatologia",
        52: "Neuroradiologia",
        53: "Patologia clinica (laboratorio di analisi chimico-cliniche e microbiologia)",
        54: "Radiodiagnostica",
        55: "Igiene, epidemiologia e sanità pubblica",
        56: "Igiene degli alimenti e della nutrizione",
        57: "Medicina del lavoro e sicurezza degli ambienti di lavoro",
        58: "Medicina generale (medici di famiglia)",
        59: "Continuità assistenziale",
        60: "Pediatria (pediatri di libera scelta)",
        106: "Scienza dell'alimentazione e dietetica",
        107: "Direzione medica di presidio ospedaliero",
        108: "Organizzazione dei servizi sanitari di base",
        111: "Audiologia e foniatria",
        112: "Psicoterapia",
        113: "Privo di specializzazione",
        114: "Cure palliative",
        115: "Epidemiologia",
        116: "Medicina di comunità",
        117: "Medicina subacquea e iperbarica"
    },
    4: {
        61: "Igiene degli allevamenti e delle produzioni zootecniche",
        62: "Igiene prod., trasf., commercial., conserv. E tras. Alimenti di origine animale e derivati",
        63: "Sanità animale"
    },
    2: {64: "Odontoiatria"},
    3: {66: "Farmacia ospedaliera", 67: "Farmacia territoriale"},
    6: {68: "Biologo"},
    7: {76: "Chimica analitica"},
    5: {77: "Psicoterapia", 78: "Psicologia"},
    8: {79: "Fisica sanitaria"},
    9: {80: "Assistente sanitario"},
    10: {81: "Dietista"},
    12: {82: "Fisioterapista"},
    11: {83: "Educatore professionale"},
    13: {84: "Igienista dentale"},
    14: {85: "Infermiere"},
    15: {86: "Infermiere pediatrico"},
    16: {87: "Logopedista"},
    17: {88: "Ortottista/assistente di oftalmologia"},
    18: {89: "Ostetrica/o"},
    19: {90: "Podologo"},
    24: {91: "Tecnico della riabilitazione psichiatrica"},
    22: {92: "Tecnico della fisiopatologia cardiocircolatoria e perfusione cardiovascolare"},
    28: {93: "Tecnico sanitario laboratorio biomedico"},
    27: {94: "Tecnico sanitario di radiologia medica"},
    20: {95: "Tecnico audiometrista"},
    21: {96: "Tecnico audioprotesista"},
    25: {98: "Tecnico di neurofisiopatologia"},
    26: {99: "Tecnico ortopedico"},
    29: {100: "Terapista della neuro e psicomotricità dell'età evolutiva"},
    30: {101: "Terapista occupazionale"},
    23: {105: "Tecnico della prevenzione nell'ambiente e nei luoghi di lavoro"},
}

export const EMPLOYEE = [
    'Seleziona',
    'Dipendente',
    'Libero professionista',
    'Convenzionato',
    'Privo di occupazione'
]

export enum SubscriptionStatus {
    CANCELLED = -1,
    PENDING = 0,
    PAYED = 1,
    CONFIRMED = 2,
}

export const SUBSCRIPTION_LABEL = {
    [SubscriptionStatus.CANCELLED]: "CANCELLATA",
    [SubscriptionStatus.PENDING]: "IN ATTESA",
    [SubscriptionStatus.PAYED]: "PAGATO",
    [SubscriptionStatus.CONFIRMED]: "CONFERMATA",
}

export enum SubscriptionParticipation {
    STATUS_PRESENT = 1,
    STATUS_ABSENT = 0,
}

export const PARTICIPANT_LABEL = {
    [SubscriptionParticipation.STATUS_PRESENT]: "PRESENTE",
    [SubscriptionParticipation.STATUS_ABSENT]: "ASSENTE"
}

export const HOME_CATEGORIES = {
    0: 'Medico chirurgo',
    1: 'Odontoiatra',
    2: 'Farmacista',
    3: 'Veterinario',
    4: 'Psicologo',
    5: 'Educatore professionale',
    6: 'Fisioterapista',
    7: 'Infermiere',
    8: 'Logopedista',
    9: 'Ostetrica',
    10: 'Tecnico ambiente',
    11: 'Tecnico riabilitazione',
    12: 'Tecnico radiologo',
    13: 'Tecnico laboratorio',
}