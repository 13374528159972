import React, {Fragment} from 'react';
import {useSearchParams} from "react-router-dom";

const AppFooter = () => {

    const [searchParams] = useSearchParams();

    if (searchParams.get("public") === 1 || parseInt(searchParams.get('layout')) === 0) {
        return null;
    }

    return <Fragment>
        <div className="app-footer">
            <div className="app-footer__inner">
                <div className="app-footer-left">
                    <ul className="nav">
                        <li className="nav-item">
                            <a href="#" className="nav-link">
                                Footer Link 1
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="#" className="nav-link">
                                Footer Link 2
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="app-footer-right">
                    <ul className="nav">
                        <li className="nav-item">
                            <a href="#" className="nav-link">
                                Footer Link 3
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="#" className="nav-link">
                                <div className="badge bg-success me-1 ms-0">
                                    <small>NEW</small>
                                </div>
                                Footer Link 4
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </Fragment>
}

export default AppFooter;