import React, {useEffect, useState} from "react";
import {getCourses} from "../../../services/course.service";

import "./style.css";
import {CourseModel} from "../../../@types/model";
import {useSearchParams} from "react-router-dom";
import CourseRow from "./row";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

export default function CoursesList() {
    const [searchParams] = useSearchParams();

    const [courses, setCourses] = useState<CourseModel[]>([]);

    useEffect(() => {
        getCourses({
            category: searchParams.get("category"),
            status: searchParams.get("status"),
            price: searchParams.get("price"),
            formation: searchParams.get("formation"),
            subject: searchParams.get("subject"),
            starred: searchParams.get("starred"),
        }).then((response: any) => setCourses(response.data.data));
    }, [searchParams]);

    return (
        <div className='container'>
            <div className="lp-courses-bar list">
                <form className="search-courses" method="get" action="https://www.professionisanitarie.com/lp-courses/">
                    <input type="text" placeholder="Search courses..." name="c_search" value=""/>
                    <button type="submit">
                        <FontAwesomeIcon icon={faSearch}/>
                    </button>
                </form>
            </div>
            <ul className="learn-press-courses" data-layout="list">
                {courses.map((c) => (
                    <li className='course'>
                        <CourseRow key={c.id} course={c}/>
                    </li>
                ))}
            </ul>
        </div>
    );
}
