import React, {Fragment, useEffect, useState} from "react";
import "./style.css";
import {getTeachers} from "../../../services/teacher.service";

export default function Teachers() {
    const [teachers, setTeachers] = useState([]);

    useEffect(() => {
        getTeachers().then((response: any) => setTeachers(response.data.data));
    }, []);

    return <Fragment>
        <div className="couses-list-title-container">Lista docenti</div>
        <div className="table-container">
            <table className="table">
                <thead className="table-head">
                <tr className="table-row">
                    <th className="th th-1">
                        <input type={"checkbox"}/>
                    </th>
                    <th className="th th-3">Name</th>
                    <th className="th th-3">Surname</th>
                    <th className="th th-3">Username</th>
                    <th className="th th-4">Email</th>
                    <th className="th th-5">DOB</th>
                    <th className="th th-5">Fiscal code</th>
                    <th className="th th-5">Date</th>
                </tr>
                </thead>

                <tbody className="table-body">
                {teachers.map((item: any, index) => {
                    return (
                        <tr key={index} className="table-row hover-grey">
                            <td className="td td-1">
                                <input
                                    type={"checkbox"}
                                    checked={false}
                                    onChange={() => {
                                    }}
                                />
                            </td>
                            <td className="td td-3">{item.name}</td>
                            <td className="td td-4">{item.surname}</td>
                            <td className="td td-5">{item.username}</td>
                            <td className="td td-5">{item.email}</td>
                            <td className="td td-5">{item.dob}</td>
                            <td className="td td-5">{item.fiscal_code}</td>
                            <td className="td td-5">{item.indt}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    </Fragment>
}
