import axios from "axios";

export const register = (username, email, password) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/user/register`, {
        username,
        email,
        password,
    });
};

export const login = (username, password) => {
    return axios
        .post(`${process.env.REACT_APP_API_URL}/user/login`, {
            username,
            password,
        })
        .then((response) => {
            if (response.data.data.auth_key) {
                localStorage.setItem("user", JSON.stringify(response.data.data));
            }
            return response.data.data;
        });
};

export const logout = () => {
    localStorage.removeItem("user");
};

export const resetPassword = async (username) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/user/recover-password?username=${username}`)
};

export const newPassword = async (token, password, password2) => {
    return await axios
        .post(`${process.env.REACT_APP_API_URL}/user/reset-password`, {
            token,
            password,
            password2,
        });
};