import React from "react";
import ReactDOM from 'react-dom/client';

import "./assets/base.css";
import configureStore from "./config/configureStore";
import {Provider} from "react-redux";
import App from "./App";

const store = configureStore();
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <App/>
    </Provider>
);
