import React, {useEffect, useState} from "react";
import {Input, Select} from "../Inputs";
import {getLastSubscription, subscribeToCourse} from "../../services/course.service";

import {Bounce, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {DISCIPLINE, EMPLOYEE, PROFESSION} from "../../constants/subscription";
import {SubscriptionModel, UserModel} from "../../@types/model";
import {LOGIN_SUCCESS} from "../../actions/types";
import {useDispatch} from "react-redux";
import moment from "moment";

interface RegistrationFormInterface {
    id: number;
    isPrivate: boolean;
    user: undefined | UserModel;
    setSubscription: any
}

function RegistrationForm(props: RegistrationFormInterface) {

    const {id, isPrivate, user, setSubscription} = props;

    const [cognome, setCognome] = useState(user?.surname || '');
    const [nome, setNome] = useState(user?.name || '');
    const [dataDiNascita, setDataDiNascita] = useState(user?.dob || '');
    const [luogoDiNascita, setLuogoDiNascita] = useState(user?.lob || '');
    const [provinciaDiNascita, setProvinciaDiNascita] = useState(user?.pob || '');
    const [indirizzo, setIndirizzo] = useState("");
    const [cap, setCap] = useState("");
    const [citta, setCitta] = useState("");
    const [provincia, setProvincia] = useState("");
    const [codiceFiscale, setCodiceFiscale] = useState("");
    const [postaElettronica, setPostaElettronica] = useState(user?.email || '');
    const [profSanitaria, setProfSanitaria] = useState(0);
    const [disciplinaSanitaria, setDisciplinaSanitaria] = useState(profSanitaria);
    const [liberoProf, setLiberoProf] = useState("");
    const [enteDiAppartenenza, setEnteDiAppartenenza] = useState("");
    const [paroladordine, setParoladordine] = useState("");
    const [note, setNote] = useState("");

    const [listOfDiscipline, setListOfDiscipline] = useState<string[]>([]);
    const [confirmMessage, setConfirmMessage] = useState<string>("");

    const [errors, setErrors] = useState({});

    const dispatch = useDispatch();

    useEffect(() => {
        setListOfDiscipline(DISCIPLINE[profSanitaria] ?? [])
        setDisciplinaSanitaria(profSanitaria)
    }, [profSanitaria])

    useEffect(() => {
        console.log("getLastSubscription")
        setNome(user?.name || '');
        setCognome(user?.surname || '');
        setDataDiNascita(user?.dob || '');
        setLuogoDiNascita(user?.lob || '');
        setProvinciaDiNascita(user?.pob || '');
        setPostaElettronica(user?.email || '');
        if (user) {
            getLastSubscription().then((s: SubscriptionModel) => {
                if (!s) return
                setIndirizzo(s.address);
                setCap(s.city_code);
                setCitta(s.city);
                setProvincia(s.province);
                setCodiceFiscale(s.fiscal_code);
                setProfSanitaria(s.profession);
                setDisciplinaSanitaria(s.discipline);
                setLiberoProf(s.employee);
                setEnteDiAppartenenza(s.organization);
            })
        }
    }, [user])

    function onFormValidate() {
        let errors = {}
        if (!nome) {
            errors['name'] = "nome non può essere vuoto";
        }
        if (!cognome) {
            errors['surname'] = "cognome non può essere vuoto";
        }
        if (!codiceFiscale) {
            errors['fiscal_code'] = "codice fiscale non può essere vuoto";
        }
        if (!postaElettronica) {
            errors['email'] = "email non può essere vuoto";
        }
        if (!dataDiNascita) {
            errors['dob'] = "data non può essere vuoto";
        }
        if (!luogoDiNascita) {
            errors['lob'] = "luogo di nascita non può essere vuoto";
        }
        if (!provinciaDiNascita) {
            errors['pob'] = "campo non valido"
        }
        if (!indirizzo) {
            errors['address'] = "indirizzo non può essere vuoto"
        }
        if (!cap) {
            errors['city_code'] = "cap non valido"
        }
        if (!citta) {
            errors['city'] = "citta non può essere vuoto"
        }
        if (!provincia) {
            errors['province'] = "campo non valido"
        }
        if (!profSanitaria) {
            errors['profession'] = "prof sanitaria non può essere vuoto"
        }
        if (!disciplinaSanitaria) {
            errors['discipline'] = "disciplina sanitaria non può essere vuoto"
        }
        if (!liberoProf) {
            errors['employee'] = "occupazione non può essere vuoto"
        }
        return errors
    }

    const onSubmit = () => {
        const errors = onFormValidate()
        setErrors(errors)
        if (Object.keys(errors).length) {
            return
        }
        subscribeToCourse(id, {
            surname: cognome.trim(),
            name: nome.trim(),
            dob: moment(dataDiNascita, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            lob: luogoDiNascita.trim(),
            pob: provinciaDiNascita.trim(),
            address: indirizzo.trim(),
            city_code: cap.trim(),
            city: citta.trim(),
            province: provincia.trim(),
            fiscal_code: codiceFiscale.trim(),
            email: postaElettronica.trim(),
            profession: profSanitaria,
            discipline: disciplinaSanitaria,
            employee: liberoProf,
            organization: enteDiAppartenenza?.trim(),
            password: paroladordine.trim().toUpperCase(),
            note: note.trim(),
        })
            .then((response) => {
                if (response.data.data.user.auth_key) {
                    localStorage.setItem("user", JSON.stringify(response.data.data.user));
                }
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: {user: response.data.data.user},
                });
                setSubscription(response.data.data.subscription)
                const message = "Complimenti la tua iscrizione è andata a buon fine"
                showNotify(message);
                setConfirmMessage(message)
                setNome(user?.name || '');
                setCognome(user?.surname || '');
                setDataDiNascita(user?.dob || '');
                setLuogoDiNascita(user?.lob || '');
                setProvinciaDiNascita(user?.pob || '');
                setIndirizzo("");
                setCap("");
                setCitta("");
                setProvincia("");
                setCodiceFiscale("");
                setPostaElettronica(user?.email || '');
                setProfSanitaria(0);
                setDisciplinaSanitaria(0);
                setLiberoProf("0");
                setEnteDiAppartenenza("");
                setParoladordine("");
                setNote("");
                setTimeout(() => setConfirmMessage(""), 5000)
            })
            .catch(({response}) => {
                setErrors(response.data.data)
                alert(response.data.error.message)
            });
    };

    const showNotify = (message: string) =>
        toast(message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
        });

    return (
        <div className="registration-form">
            <div className="row">
                <div className="col-md-6 registration-field">
                    <Input
                        inputValue={cognome}
                        setInputValue={setCognome}
                        title={"Cognome"}
                        type={"text"}
                        placeholder={"scrivi qui..."}
                        forceCase='upper'
                        errors={errors['surname']}
                        readOnly={!!user}
                    />
                </div>
                <div className="col-md-6 registration-field">
                    <Input
                        inputValue={nome}
                        setInputValue={setNome}
                        title={"Nome"}
                        type={"text"}
                        placeholder={"scrivi qui..."}
                        forceCase='upper'
                        errors={errors['name']}
                        readOnly={!!user}
                    />

                </div>
            </div>
            <div className="row">
                <div className="col-md-3 registration-field">
                    <Input
                        inputValue={dataDiNascita}
                        setInputValue={setDataDiNascita}
                        title={"Data di nascita"}
                        errors={errors['dob']}
                        readOnly={!!user}
                        placeholder='GG/MM/AAAA'
                        type='text'
                    />
                </div>
                <div className="col-md-7 registration-field">
                    <Input
                        inputValue={luogoDiNascita}
                        setInputValue={setLuogoDiNascita}
                        title={"Luogo"}
                        type={"text"}
                        placeholder={"scrivi qui..."}
                        forceCase='upper'
                        errors={errors['lob']}
                        readOnly={!!user}
                    />
                </div>
                <div className="col-md-2 registration-field">
                    <Input
                        inputValue={provinciaDiNascita}
                        setInputValue={setProvinciaDiNascita}
                        title={"Prov"}
                        type={"text"}
                        maxLength={2}
                        placeholder={"scrivi qui..."}
                        forceCase='upper'
                        errors={errors['pob']}
                        readOnly={!!user}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 registration-field">
                    <Input
                        inputValue={indirizzo}
                        setInputValue={setIndirizzo}
                        title={"Indirizzo"}
                        type={"text"}
                        placeholder={"scrivi qui..."}
                        forceCase='upper'
                        errors={errors['address']}
                    />
                </div>
                <div className="col-md-2 registration-field">
                    <Input
                        inputValue={cap}
                        setInputValue={setCap}
                        title={"CAP"}
                        type={"text"}
                        placeholder={"00000"}
                        maxLength={5}
                        errors={errors['city_code']}
                    />
                </div>
                <div className="col-md-8 registration-field">
                    <Input
                        inputValue={citta}
                        setInputValue={setCitta}
                        title={"Città"}
                        type={"text"}
                        placeholder={"scrivi qui..."}
                        forceCase='upper'
                        errors={errors['city']}
                    />
                </div>
                <div className="col-md-2 registration-field">
                    <Input
                        inputValue={provincia}
                        setInputValue={setProvincia}
                        title={"Prov"}
                        type={"text"}
                        placeholder={"scrivi qui..."}
                        maxLength={2}
                        forceCase='upper'
                        errors={errors['province']}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 registration-field">
                    <Input
                        inputValue={codiceFiscale}
                        setInputValue={setCodiceFiscale}
                        title={"Codice Fiscale"}
                        type={"text"}
                        maxLength={16}
                        placeholder={"scrivi qui..."}
                        forceCase='upper'
                        errors={errors['fiscal_code']}
                    />

                </div>
                <div className="col-md-6 registration-field">
                    <Input
                        inputValue={postaElettronica}
                        setInputValue={setPostaElettronica}
                        title={"Indirizzo posta elettronica"}
                        type={"text"}
                        placeholder={"scrivi qui..."}
                        forceCase='lower'
                        errors={errors['email']}
                        readOnly={!!user}
                    />

                </div>
            </div>
            <div className="row">
                <div className="col-md-6 registration-field">
                    <Select
                        inputValue={profSanitaria}
                        setInputValue={setProfSanitaria}
                        title={"Professione sanitaria"}
                        list={PROFESSION}
                        errors={errors['profession']}
                    />
                </div>
                <div className="col-md-6 registration-field">
                    <Select
                        inputValue={disciplinaSanitaria}
                        setInputValue={setDisciplinaSanitaria}
                        title={"Disciplina sanitaria"}
                        list={listOfDiscipline}
                        errors={errors['disciplie']}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 registration-field">
                    <Select
                        inputValue={liberoProf}
                        setInputValue={setLiberoProf}
                        title={"Occupazione"}
                        list={EMPLOYEE}
                        errors={errors['employee']}
                    />
                </div>
                <div className="col-md-6 registration-field">
                    <Input
                        inputValue={enteDiAppartenenza}
                        setInputValue={setEnteDiAppartenenza}
                        title={"Ente di appartenenza"}
                        type={"text"}
                        placeholder={"scrivi qui..."}
                        errors={errors['organization']}
                    />
                </div>
            </div>

            {isPrivate && <div className="row">
                <div className="col-md-6 registration-field">
                    <Input
                        inputValue={paroladordine}
                        setInputValue={setParoladordine}
                        title={"Password"}
                        type={"text"}
                        placeholder={"scrivi qui..."}
                        errors={errors['password']}
                    />
                </div>
            </div>
            }

            <div className="row">
                <div className="col-12 input-container form-group">
                    <label> Note</label>
                    <textarea
                        value={note}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                            setNote(e.target.value)
                        }
                        className="col-12"
                        rows={6}
                    />
                </div>
            </div>

            {confirmMessage !== '' ? <div className="alert alert-success">{confirmMessage}</div> : null}

            <div>
                <button onClick={onSubmit} className="btn btn-info w-100">
                    Invia
                </button>
            </div>
        </div>
    );
}

export default RegistrationForm;
