import React, {Fragment, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getCourseById} from "../../../services/course.service";
import "./style.css";
import {CourseModel} from "../../../@types/model";

const Course = () => {

    const [isLoading, setIsLoading] = useState(false);

    const [course, setCourse] = useState<CourseModel>();

    const {id} = useParams<{ id: string }>();

    useEffect(() => {
        setIsLoading(true);
        getCourseById(parseInt(id ?? "0")).then((response: any) => {
            setCourse(response.data.data);
            setIsLoading(false);
        });
    }, [id]);

    return (
        <div className="course-screen">
            {isLoading ? (
                <div className="loading-container">Loading...</div>
            ) : (
                <Fragment>
                    {" "}
                    <div className="layout-column">
                        <img
                            className="thumbnail-image"
                            alt=""
                            src={course?.thumbnail!}
                        ></img>
                        <div className="abstract-container">
                            <h3 className="abstract-title"> {course?.name}</h3>
                            <h5 className="abstract-text">{course?.abstract}</h5>
                        </div>
                    </div>
                    <div className="layout-column">
                        <div className="data-row">
                            <div className="bold-text">Luogo: &nbsp;</div>
                            <div className="text">{course?.place}</div>
                        </div>

                        <div className="data-row">
                            <div className="bold-text">Selde: &nbsp;</div>
                            <div className="text">{course?.location}</div>
                        </div>

                        <div className="data-row">
                            <div className="bold-text">Prezzo: &nbsp;</div>
                            <div className="text">{course?.price}</div>
                        </div>

                        <div className="data-row">
                            <div className="bold-text">Posti: &nbsp;</div>
                            <div className="text">{course?.seats}</div>
                        </div>

                        <div className="data-row">
                            <div className="bold-text">Ore: &nbsp;</div>
                            <div className="text">{course?.hours}</div>
                        </div>

                        <div className="data-row">
                            <div className="bold-text">da: &nbsp;</div>
                            <div className="text">{course?.date_start}</div>
                        </div>

                        <div className="data-row">
                            <div className="bold-text">al: &nbsp;</div>
                            <div className="text">{course?.date_end}</div>
                        </div>

                        <div className="data-row">
                            <div className="bold-text">Crediti Sanitari: &nbsp;</div>
                            <div className="text">{course?.credits_san}</div>
                        </div>

                        <div className="data-row">
                            <div className="bold-text">Crediti Sociali: &nbsp;</div>
                            <div className="text">{course?.credits_soc}</div>
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    );
};

export default Course;
