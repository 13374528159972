import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import "./style.css";

import Errors from "../errors/index";
import {newPassword} from "../../../services/auth.service";

const NewPassword = () => {

    const {token} = useParams();

    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [loading, setLoading] = useState(false);

    let {isLoggedIn, errorMessage} = useSelector((state) => state.auth);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const onChangePassword = (e) => {
        setPassword(e.target.value);
    };

    const onChangePassword2 = (e) => {
        setPassword2(e.target.value);
    };

    const handleForm = (e) => {
        e.preventDefault();
        setLoading(true);
        newPassword(token, password, password2)
            .then(() => {
                navigate("/");
            })
            .catch((error, response) => {
                setLoading(false);
            });
    };

    if (isLoggedIn) {
        return navigate("/");
    }

    return (
        <div className="new-password-body">
            <div className="container" style={{maxWidth: "500px"}}>
                <div className="new-password-box">
                    <div className="col-12 new-password-key text-center">
                        <i className="pe-7s-user"></i>
                    </div>
                    <div className="col-12 new-password-title text-center">RECUPERO PASSWORD</div>
                    <div className="col-12 new-password-form px-5">
                        <form onSubmit={handleForm}>
                            <div className="form-group">
                                <label htmlFor="password" className="form-control-label">
                                    PASSWORD
                                </label>
                                <input
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    value={password}
                                    onChange={onChangePassword}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password2" className="form-control-label">
                                    RIPETI PASSWORD
                                </label>
                                <input
                                    type="password"
                                    className="form-control"
                                    name="password2"
                                    value={password2}
                                    onChange={onChangePassword2}
                                />
                            </div>

                            <div className="col-12 new-passwordbttm">
                                <div className="col-12 new-password-btm new-password-text">
                                    {errorMessage ? (
                                        <Errors type="error" message={errorMessage}/>
                                    ) : (
                                        ""
                                    )}
                                </div>

                                <div className="col-6 new-password-btm new-password-button">
                                    <button
                                        className="btn btn-outline-primary"
                                        disabled={loading}
                                    >
                                        {loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>New Password</span>
                                    </button>
                                </div>

                                <div className="clearfix"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewPassword;
