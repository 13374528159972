import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from 'react-router-dom';
import "./style.css";
import 'react-toastify/dist/ReactToastify.css';
import {Bounce, toast} from "react-toastify";
import {teacherSubscribeToCourse, uploadPDF} from "../../../services/teacher.service";
import {CourseModel} from "../../../@types/model";
import {getCourseByCode} from "../../../services/course.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudArrowDown, faCloudArrowUp} from "@fortawesome/free-solid-svg-icons";

const TeacherSubscription = () => {
    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [course, setCourse] = useState<CourseModel>();
    const [subscriptionForm, setSubscriptionForm] = useState<TeacherSubscriptionForm>({});
    const [showSuccessAlert, setShowSuccessAlert] = useState<object>({
        conflict: {active: false, fileName: ''},
        payment: {active: false, fileName: ''},
        refund: {active: false, fileName: ''},
        assignment: {active: false, fileName: ''}
    });
    const [progressBarPercentage, setProgressBarPercentage] = useState(0);

    useEffect(() => {
        setIsLoading(true);
        getCourseByCode(parseInt(id ?? "0")).then((response: any) => {
            setCourse(response.data.data);
            setIsLoading(false);
        });
    }, [id]);

    useEffect(() => {
        setTimeout(() => setProgressBarPercentage(prev => prev + 5), 50);
    }, [showSuccessAlert, progressBarPercentage]);

    const onSubmit = (e) => {
        e.preventDefault()
        teacherSubscribeToCourse(parseInt(id ?? "0"), subscriptionForm)
            .then(() => {
                navigate("/")
                showNotify()
            }).catch((response) => {
            showNotifyError(response.data.error.message)
        })
    };

    const showNotifyError = (error) => toast("C'è stato un errore durante l'invio dei dati: " + error, {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'bottom-center',
        type: 'error'
    });

    const showNotify = () => toast("Complimenti la tua iscrizione è andata a buonfine", {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'bottom-center',
        type: 'success'
    });

    let formData = new FormData();

    const onFileUpload = (event, type) => {
        const fileRef: any = event.target.files[0];
        if (fileRef && id) {
            formData.append("file", fileRef);
            uploadPDF(id, type, formData)
                .then((response) => {
                    setSubscriptionForm(Object.assign({}, subscriptionForm, {[type]: response.data.data.filename}))
                    showSuccessAlert[type].active = true;
                    showSuccessAlert[type].fileName = fileRef.name;
                    setProgressBarPercentage(0);
                });
        }
    };

    if (isLoading) {
        return <div className="loading-container">Loading...</div>
    }

    function showAlertJSX(type: string): React.ReactNode {
        return <div className="alert-container">
            <div className="alert-icon"></div>
            <div className="alert-right-section">
                <div className="alert-text-and-close-btn">
                    <span className="alert-text">{showSuccessAlert[type].fileName}</span>
                    <span className="close-button" onClick={() => showSuccessAlert[type].active = false}>&times;</span>
                </div>
                <div className="progress" role="progressbar">
                    <div className="progress-bar progress-bar-striped progress-bar-animated bg-success"
                         style={{width: `${progressBarPercentage}%`}}/>
                </div>
            </div>
        </div>;
    }

    return <div className='container-teacher-subscribe'>
        <div className="course">
            <div className="course-header">
                <h2 className="course-title">{course?.name}</h2>
                <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
                <span/>
            </div>
            <div className="course-details">
                <div>
                    <span><b>Codice:</b> {course?.code}</span>
                    <span><b>Ore:</b> {course?.hours} ore</span>
                    <span><b>Sede:</b> {course?.location}</span>
                    <span><b>Prezzo:</b> {course?.price}€</span>
                    <span><b>Da</b> {course?.date_start} <b>a</b> {course?.date_end}</span>
                    <span>
                            <b>Descrizione:</b>
                            <span dangerouslySetInnerHTML={{__html: String(course?.description)}}></span>
                        </span>
                </div>
                <img className="course-thumbnail" src={String(course?.thumbnail)} alt="course-thumbnail"/>
            </div>
        </div>

        <form className="t-registration-form">
            <div className="upload-section">
                <label htmlFor="conflict">
                    <span>Conflitto</span>
                    <a className="download-button" href={`/corso/${id}/download/conflict?public=1`} target="_blank"
                       rel="noreferrer">
                        <FontAwesomeIcon icon={faCloudArrowDown} size="lg"/>
                        Scarica Conflitto
                    </a>
                    {showSuccessAlert['conflict'].active ?
                        showAlertJSX('conflict') :
                        <div className="upload-button">
                            <FontAwesomeIcon icon={faCloudArrowUp} size="lg"/>
                            <span>Seleziona il file</span>
                            <input type='file' className="input" id="conflict" hidden
                                   onChange={(e) => onFileUpload(e, "conflict")}/>
                        </div>}
                </label>

                <label htmlFor="payment">
                    <span>Pagamento</span>
                    <a className="download-button" href={`/corso/${id}/download/payment?public=1`} target="_blank"
                       rel="noreferrer">
                        <FontAwesomeIcon icon={faCloudArrowDown} size="lg"/>
                        Scarica Pagamento
                    </a>
                    {showSuccessAlert['payment'].active ?
                        showAlertJSX('payment') :
                        <div className="upload-button">
                            <FontAwesomeIcon icon={faCloudArrowUp} size="lg"/>
                            <span>Seleziona il file</span>
                            <input type='file' className="input" id="payment" hidden
                                   onChange={(e) => onFileUpload(e, "payment")}/>
                        </div>}
                </label>

                <label htmlFor="refund">
                    <span>Rimborso</span>
                    <a className="download-button" href={`/corso/${id}/download/refund?public=1`} target="_blank"
                       rel="noreferrer">
                        <FontAwesomeIcon icon={faCloudArrowDown} size="lg"/>
                        Scarica Rimborso
                    </a>
                    {showSuccessAlert['refund'].active ?
                        showAlertJSX('refund') :
                        <div className="upload-button">
                            <FontAwesomeIcon icon={faCloudArrowUp} size="lg"/>
                            <span>Seleziona il file</span>
                            <input type='file' className="input" id="refund" hidden
                                   onChange={(e) => onFileUpload(e, "refund")}/>
                        </div>}
                </label>

                <label htmlFor="assignment">
                    <span>Assegnazione</span>
                    <a className="download-button" href={`/corso/${id}/download/assignment?public=1`} target="_blank"
                       rel="noreferrer">
                        <FontAwesomeIcon icon={faCloudArrowDown} size="lg"/>
                        Scarica Assegnazione
                    </a>
                    {showSuccessAlert['assignment'].active ?
                        showAlertJSX('assignment') :
                        <div className="upload-button">
                            <FontAwesomeIcon icon={faCloudArrowUp} size="lg"/>
                            <span>Seleziona il file</span>
                            <input type='file' className="input" id="assignment" hidden
                                   onChange={(e) => onFileUpload(e, "assignment")}/>
                        </div>}
                </label>
            </div>

            <button onClick={onSubmit} className="registration-form-button btn btn-success">
                Invia
            </button>
        </form>
    </div>
};

export default TeacherSubscription;
