import RichTextEditor from "react-rte";
import React from "react";
import "./style.css";

interface RichTextProps {
    descrRTE: RichTextEditor;
    setDescrRTE: React.Dispatch<any>;
}

const toolbarConfig = {
    display: [
        "INLINE_STYLE_BUTTONS",
        "BLOCK_TYPE_BUTTONS",
        "LINK_BUTTONS",
        "BLOCK_TYPE_DROPDOWN",
        "HISTORY_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
        {label: "Bold", style: "BOLD", className: "custom-css-class"},
        {label: "Italic", style: "ITALIC"},
        {label: "Underline", style: "UNDERLINE"},
    ],
    BLOCK_TYPE_DROPDOWN: [
        {label: "Normal", style: "unstyled"},
        {label: "Heading Large", style: "header-one"},
        {label: "Heading Medium", style: "header-two"},
        {label: "Heading Small", style: "header-three"},
    ],
    BLOCK_TYPE_BUTTONS: [
        {label: "UL", style: "unordered-list-item"},
        {label: "OL", style: "ordered-list-item"},
    ],
};

export const HtmlInputForm = (props: RichTextProps) => {
    const {descrRTE, setDescrRTE,} = props;

    return (
        <div className="rich-text-container">
            <RichTextEditor
                value={descrRTE}
                toolbarConfig={toolbarConfig}
                onChange={(descrRTE) => {
                    setDescrRTE(descrRTE);
                }}
            />
        </div>
    );
};
