import React, {Fragment} from "react";
import {useSelector} from "react-redux";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {ToastContainer} from "react-toastify";

// Layout
import AppHeader from "./layout/AppHeader";
import AppSidebar from "./layout/AppSidebar";
import AppFooter from "./layout/AppFooter";

import Users from "./page/user/index";
import Courses from "./page/course/index";
import CreateCourse from "./page/course/create";
import Course from "./page/course/view";
import CourseUsers from "./page/course/user";
import CourseTeachers from "./page/course/teacher";
import CourseVouchers from "./page/course/voucher";
import Login from "./page/user/login";
import Teachers from "./page/teacher/index";
import CourseSubscription from "./page/course/subscription";
import CoursesCatalog from "./page/course/catalog";
import TeacherCreate from "./page/teacher/create";
import ShowPDF from "./page/course/pdf/index";
import TeacherSubscription from "./page/teacher/subscribe/index";
import NewPassword from "./page/user/newPassword";
import CourseSubscriptionUpdate from "./page/course/subscription/update"
import {UserRole} from "./constants/auth";
import ShowUsersPDF from "./page/course/pdf/users";
import ShowXML from "./page/course/xml";
import CoursesList from "./page/course/list";
import getUser from "./reducers/selectors/getUser";


const App = () => {
    const user = useSelector(getUser);

    if (!user) {
        return <BrowserRouter>
            <Routes>
                <Route exact path="/iscrizione/corso/:id" element={<CourseSubscription/>}/>
                <Route exact path="/iscrizione/docente" element={<TeacherCreate/>}/>
                <Route exact path="/corsi" element={<CoursesList/>}/>
                <Route exact path="/catalogo" element={<CoursesCatalog/>}/>
                <Route exact path="/nuova-password/:token" element={<NewPassword/>}/>
                <Route path="*" element={<Login/>}/>
            </Routes>
        </BrowserRouter>
    }

    if (user.role === UserRole.USER) {
        return <BrowserRouter>
            <Routes>
                <Route exact path="/iscrizione/corso/:id" element={<CourseSubscription/>}/>
                <Route exact path="/catalogo" element={<CoursesCatalog/>}/>
                <Route path="*" element={<CoursesCatalog/>}/>
            </Routes>
            <ToastContainer/>
        </BrowserRouter>
    }

    if (user.role === UserRole.TEACHER) {
        return <BrowserRouter>
            <AppHeader/>
            <Fragment>
                <Routes>
                    <Route exact path="/iscrizione/corso/:id" element={<TeacherSubscription/>}/>
                    <Route exact path="/corso/:id/download/:pdfName" element={<ShowPDF/>}/>
                    <Route exact path="/catalogo" element={<CoursesCatalog/>}/>
                    <Route path="*" element={<CoursesCatalog/>}/>
                </Routes>
                <ToastContainer/>
            </Fragment>
        </BrowserRouter>
    }

    return <BrowserRouter>
        <AppHeader/>
        <Fragment>
            <div className="app-main">
                <AppSidebar/>
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <Routes>
                            <Route path="/" element={<Courses/>}/>
                            <Route exact path="/catalogo" element={<CoursesCatalog/>}/>
                            <Route exact path="/course" element={<Courses/>}/>
                            <Route exact path="/course/create" element={<CreateCourse/>}/>
                            <Route
                                exact
                                path="/course/:id/edit"
                                element={<CreateCourse/>}
                            />
                            <Route exact path="/course/:id" element={<Course/>}/>
                            <Route
                                exact
                                path="/course/:id/users"
                                element={<CourseUsers/>}
                            />
                            <Route
                                exact
                                path="/course/:id/teachers"
                                element={<CourseTeachers/>}
                            />
                            <Route
                                exact
                                path="/course/:id/vouchers"
                                element={<CourseVouchers/>}
                            />
                            <Route
                                exact path="/course/:id/download/:pdfName"
                                element={<ShowUsersPDF/>}
                            />
                            <Route
                                exact path="/course/:id/xml"
                                element={<ShowXML/>}
                            />
                            <Route
                                exact
                                path="/course/subscription/:id/update"
                                element={<CourseSubscriptionUpdate/>}
                            />

                            <Route exact path="/user" element={<Users/>}/>
                            <Route exact path="/teacher" element={<Teachers/>}/>
                        </Routes>
                    </div>
                    <ToastContainer/>
                    <AppFooter/>
                </div>
            </div>
        </Fragment>
    </BrowserRouter>

};

export default App;
