import React, {Fragment} from 'react';

import MetisMenu from 'react-metismenu';

import {CourseNav, TeacherNav, UserNav} from './NavItems';

const Nav = () => {
    return (
        <Fragment>
            <h5 className="app-sidebar__heading">Corsi</h5>
            <MetisMenu content={CourseNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                       classNameStateIcon="pe-7s-angle-down"/>
            <h5 className="app-sidebar__heading">Utenti</h5>
            <MetisMenu content={UserNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                       classNameStateIcon="pe-7s-angle-down"/>
            <h5 className="app-sidebar__heading">Docenti</h5>
            <MetisMenu content={TeacherNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix=""
                       classNameStateIcon="pe-7s-angle-down"/>
        </Fragment>
    );
}

export default Nav;