import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getPDFContent} from "../../../services/teacher.service";

const ShowPDF = () => {
    const {id, pdfName} = useParams();

    const [content, setContent] = useState();

    useEffect(() => {
        getPDFContent(id, pdfName).then((response) => setContent(response.data.data));
    }, []);

    return <div dangerouslySetInnerHTML={{__html: content}}/>
};

export default ShowPDF;
