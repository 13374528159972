export const CourseNav = [
    {
        icon: 'pe-7s-rocket',
        label: 'List Corsi',
        to: '/course',
    },
    {
        icon: 'pe-7s-rocket',
        label: 'Nuovo corso',
        to: '/course/create',
    },
];

export const UserNav = [
    {
        icon: 'pe-7s-graph2',
        label: 'Lista Utenti',
        to: '/user',
    }
];

export const TeacherNav = [
    {
        icon: 'pe-7s-graph2',
        label: 'Lista Docenti',
        to: '/teacher',
    },
];