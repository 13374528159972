import React, {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getCourses} from "../../../services/course.service";

import {faPen, faUser, faUserGraduate} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import "./style.css";
import {CourseModel} from "../../../@types/model";
import {faTicket} from "@fortawesome/free-solid-svg-icons/faTicket";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input,} from "reactstrap";
import {CATEGORY_LIST, STATUS_LIST} from "../../../constants/course";

export default function Courses() {
    const [coursesList, setCoursesList] = useState<CourseModel[]>();
    const [mouseOverEdit, setMouseOverEdit] = useState<boolean>(false);

    const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false);
    const [priceDropdownOpen, setPriceDropdownOpen] = useState(false);
    const [locationDropdownOpen, setLocationDropdownOpen] = useState(false);

    const [filters, setFilters] = useState({
        name: null, category: null, location: null, price: null
    });

    const navigate = useNavigate();

    useEffect(() => {
        loadCourse()
    }, []);

    const onSelectCourse = (id: number) => {
        !mouseOverEdit && navigate(`/course/${id}`);
    };

    const onCreateCourse = () => {
        navigate(`/course/create`);
    };

    const loadCourse = (_filters: object = {}) => {
        getCourses(_filters).then((response: any) => {
            setCoursesList(response.data.data)
        });
    }

    const categoryToggle = () => setCategoryDropdownOpen((prevState) => !prevState);
    const priceToggle = () => setPriceDropdownOpen((prevState) => !prevState);
    const locationToggle = () => setLocationDropdownOpen((prevState) => !prevState);

    let categoryList: CourseModel[] = [];
    if (coursesList) {
        const ids = coursesList.map((e) => e.category);
        categoryList = coursesList.filter(
            (val, index) => !ids.includes(val.category, index + 1)
        );
    }

    let locationList: CourseModel[] = [];
    if (coursesList) {
        const ids = coursesList.map((e) => e.location);
        locationList = coursesList.filter(
            (val, index) => !ids.includes(val.location, index + 1)
        );
    }

    useEffect(() => {
        const name: string = filters.name || ''
        if (name.length > 0 && name.length < 3) return
        const code = parseInt(name)
        if (!isNaN(code)) {
            loadCourse({code: code, category: filters.category, location: filters.location, price: filters.price})
        } else {
            loadCourse(filters)
        }
    }, [filters]);

    return (
        <Fragment>
            <div className="couses-list-title-container">Lista dei corsi</div>
            <div className="button-container">
                <div className="d-flex filter-button">
                    <Input
                        placeholder="Enter title"
                        onChange={(e) => setFilters(Object.assign({}, filters, {name: e.target.value}))}
                    />
                    <Dropdown
                        isOpen={categoryDropdownOpen}
                        toggle={categoryToggle}
                    >
                        <DropdownToggle caret>Category</DropdownToggle>
                        <DropdownMenu>
                            {categoryList &&
                                categoryList.map((val: any) => (
                                    <DropdownItem
                                        onChange={(e) => setFilters(Object.assign({}, filters, {category: val.category}))}
                                    >
                                        {val.category}
                                    </DropdownItem>
                                ))}
                        </DropdownMenu>
                    </Dropdown>

                    <Dropdown
                        isOpen={priceDropdownOpen}
                        toggle={priceToggle}
                    >
                        <DropdownToggle caret>Price</DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem
                                name="free"
                                onChange={(e) => setFilters(Object.assign({}, filters, {price: 0}))}
                            >
                                Free
                            </DropdownItem>
                            <DropdownItem
                                name="payment"
                                onChange={(e) => setFilters(Object.assign({}, filters, {price: 1}))}
                            >
                                Payment
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    <Dropdown
                        isOpen={locationDropdownOpen}
                        toggle={locationToggle}
                    >
                        <DropdownToggle caret>Location</DropdownToggle>
                        <DropdownMenu>
                            {locationList &&
                                locationList.map((val: any) => (
                                    <DropdownItem
                                        onChange={(e) => setFilters(Object.assign({}, filters, {location: val.location}))}
                                    >
                                        {val.location}
                                    </DropdownItem>
                                ))}
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <button onClick={onCreateCourse} className="btn btn-primary">
                    Crea Corso
                </button>
            </div>
            <table className="courses-table">
                <thead className="courses-table-head">
                <tr className="courses-table-row">
                    <th className="courses-table-head-cell">Thumbnail</th>
                    <th className="courses-table-head-cell">Titolo</th>
                    <th className="courses-table-head-cell">Categoria</th>
                    <th className="courses-table-head-cell">Stato</th>
                    <th className="courses-table-head-cell">Data inizio</th>
                    <th className="courses-table-head-cell">Luogo</th>
                    <th className="courses-table-head-cell">Sede</th>
                    <th className="courses-table-head-cell">Prezzo</th>
                    <th className="courses-table-head-cell">Posti</th>
                    <th className="courses-table-head-cell">Ore</th>
                    <th className="courses-table-head-cell"></th>
                </tr>
                </thead>
                <tbody className="courses-table-body">
                {coursesList?.map((course) => {
                    return (
                        <tr
                            className="courses-table-body-row"
                            key={course.id}
                            onClick={() => onSelectCourse(course.id)}
                        >
                            <td className="courses-table-body-cell courses-table-body-image-cell body-cell-1">
                                <img
                                    src={course?.thumbnail!}
                                    alt=""
                                    className=" courses-table-body-thumbnail-image"
                                />
                            </td>
                            <td className="courses-table-body-cell body-cell-2">
                                {course.name}
                            </td>
                            <td className="courses-table-body-cell body-cell-3">
                                {CATEGORY_LIST[course.category]?.substring(0, 20) + (CATEGORY_LIST[course.category]?.length > 20 ? '...' : '')}
                            </td>
                            <td className="courses-table-body-cell body-cell-4">
                                {STATUS_LIST[course.status]}
                            </td>
                            <td className="courses-table-body-cell body-cell-5">
                                {course.date_start}
                            </td>
                            <td className="courses-table-body-cell body-cell-6">
                                {course.place}
                            </td>
                            <td className="courses-table-body-cell body-cell-7">
                                {course.location}
                            </td>
                            <td className="courses-table-body-cell body-cell-8">
                                {parseFloat(course.price) > 0 ? course.price + '€' : 'GRATUITO'}
                            </td>
                            <td className="courses-table-body-cell body-cell-9">
                                {course.subscriptions} / {course.seats}
                            </td>
                            <td className="courses-table-body-cell body-cell-10">
                                {course.hours}
                            </td>
                            <td
                                className="courses-table-body-cell body-cell-11 btn-group"
                                onMouseEnter={() => setMouseOverEdit(true)}
                                onMouseLeave={() => setMouseOverEdit(false)}
                            >
                                <button
                                    className={"mb-2 mr-2 btn btn-primary"}
                                    onClick={() => navigate(`/course/${course?.id}/users`)}
                                >
                                    <FontAwesomeIcon icon={faUser}/>
                                </button>
                                <button
                                    className={"mb-2 mr-2 btn btn-info"}
                                    onClick={() => navigate(`/course/${course?.id}/teachers`)}
                                >
                                    <FontAwesomeIcon icon={faUserGraduate}/>
                                </button>
                                <button
                                    className={"mb-2 mr-2 btn btn-light"}
                                    onClick={() => navigate(`/course/${course?.id}/vouchers`)}
                                >
                                    <FontAwesomeIcon icon={faTicket}/>
                                </button>
                                <button
                                    className={"mb-2 mr-2 btn btn-success"}
                                    onClick={() => navigate(`/course/${course?.id}/edit`)}
                                >
                                    <FontAwesomeIcon icon={faPen}/>
                                </button>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </Fragment>
    );
}
