import {useEffect, useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {getUsersPdfByCourse} from "../../../services/course.service";

const ShowUsersPDF = () => {
    const {id, pdfName} = useParams();

    const [content, setContent] = useState();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const users = searchParams.get("users").split(',').map(i => parseInt(i))
        getUsersPdfByCourse(parseInt(id), users, pdfName).then((response) => setContent(response.data.data));
    }, []);

    return <div dangerouslySetInnerHTML={{__html: content}}/>
};

export default ShowUsersPDF;
