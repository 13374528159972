import {applyMiddleware, combineReducers, createStore} from 'redux';
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";
import reducers from '../reducers';

const middleware = [thunk];

export default function configureStore() {
    return createStore(
        combineReducers({
            ...reducers
        }),
        composeWithDevTools(applyMiddleware(...middleware))
    );
}