import React, {Fragment, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {logoutAction} from '../../../actions/auth';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";


const UserBox = () => {

    const {user} = useSelector((state: any) => state.auth);
    const dispatch: any = useDispatch();

    const logOut = useCallback(() => {
        dispatch(logoutAction());
    }, [dispatch]);

    return (
        <Fragment>
            <div className="header-btn-lg pe-0">
                <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                        <div className="widget-content-left" onClick={logOut}
                             style={{cursor: "pointer"}}
                        >
                            <div className="rounded-circle float-start text-center p-1"
                                 style={{height: "42px", width: "42px", backgroundColor: "lightgray"}}>
                                <FontAwesomeIcon icon={faUser} className='fa-2x'/>
                            </div>
                            <div className="mx-3" style={{display: "inline-block"}}>
                                <div className="widget-heading">
                                    {user.name} {user.surname}
                                </div>
                                <div className="widget-subheading">
                                    {user.role}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )

}

export default UserBox;