import React, {Fragment, useEffect, useState} from "react";
import {Button, Input,} from "reactstrap";
import {getTeachersToCourse} from "../../../services/course.service";
import "./style.css"
import {useParams} from "react-router-dom";

export default function CourseTeachers() {

    const {id} = useParams();

    const [teachers, setTeachers] = useState<any>();
    const [filterValue, setFilterValue] = useState<any>();
    const [filterTeachers, setFilterTeachers] = useState<any>();

    useEffect(() => {
        getTeachersToCourse(parseInt(id ?? "0")).then((response: any) => setTeachers(response.data.data));
    }, []);

    useEffect(() => {
        if (teachers !== undefined && filterValue === undefined) {
            setFilterTeachers(teachers)
        } else if (teachers !== undefined) {
            setFilterTeachers(
                teachers.filter((item) => {
                    return item.name.toLowerCase().includes(filterValue);
                })
            );
        }

    }, [teachers, filterValue]);
    return (
        <Fragment>
            <div className="couses-list-title-container">Lista docenti</div>
            <div className="d-flex justify-content teacher-Header">
                <div className="searchInput">
                    <Input
                        placeholder="Enter value"
                        onChange={(e) => setFilterValue(e.target.value)}
                    />
                </div>
                <div className="teacherBtn">
                    <Button
                        color="primary"
                        onClick={() => null}
                    >
                        Approva
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => null}
                    >
                        Genera PDF
                    </Button>
                </div>
            </div>
            {/* table */}
            <div className="table-container">
                <table className="table">
                    <thead className="table-head">
                    <tr className="table-row">
                        <th className="th th-1">
                            <input type={"checkbox"}/>
                        </th>
                        <th className="th th-4">Name</th>
                        <th className="th th-4">Surname</th>
                        <th className="th th-5">Role</th>
                        <th className="th th-3">Credits</th>
                    </tr>
                    </thead>

                    <tbody className="table-body">
                    {filterTeachers?.map((item: any, index) => {
                        return (
                            <tr key={index} className="table-row hover-grey">
                                <td className="td td-1">
                                    <input
                                        type={"checkbox"}
                                        checked={false}
                                        onChange={() => {
                                        }}
                                    />
                                </td>
                                <td className="td td-3">{item.name}</td>
                                <td className="td td-4">{item.surname}</td>
                                <td className="td td-5">{item.role}</td>
                                <td className="td td-5">{item.credits}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        </Fragment>
    );
}
