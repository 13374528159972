import React from "react";
import {DateInputProps, DateInputTextProps, FileInputProps, InputProps, SelectProps} from "../../@types/inputs";
import moment from "moment";

export const Input = (props: InputProps) => {
    const {title, type, inputValue, setInputValue, placeholder, errors, maxLength, forceCase, readOnly} =
        props;

    const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        switch (forceCase) {
            case 'upper':
                setInputValue(event.target.value.toUpperCase());
                break;
            case 'lower':
                setInputValue(event.target.value.toLowerCase());
                break;
            default:
                setInputValue(event.target.value);
        }

    };

    return (
        <div className="position-relative form-group">
            <label>{title}</label>
            <input
                type={type}
                className={`${errors ? "is-invalid" : ""} form-control`}
                value={inputValue!}
                onChange={inputHandler}
                placeholder={placeholder}
                maxLength={maxLength}
                readOnly={readOnly}
            />
            {errors && <div className="invalid-tooltip">{errors}</div>}
        </div>
    );
};

export const Select = (props: SelectProps) => {
    const {title, inputValue, setInputValue, list, errors} = props;

    const inputHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setInputValue(event.target.value);
    };

    return (
        <div className="position-relative form-group">
            <label>{title}</label>
            <select
                className={`${errors ? "is-invalid" : ""} form-control`}
                value={inputValue}
                onChange={inputHandler}
            >
                {Object.keys(list).map((itemId) => {
                    return (
                        <option key={itemId} className="option" value={itemId}>
                            {list[itemId]}
                        </option>
                    );
                })}
            </select>
            {errors && <div className="invalid-tooltip">{errors}</div>}
        </div>
    );
};

export const DateInput = (props: DateInputProps) => {
    const {title, type, inputValue, setInputValue, errors, readOnly} = props;

    const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    return (
        <div className="position-relative form-group">
            <label>{title}</label>
            <input
                type={type}
                className={`${errors ? "is-invalid" : ""} form-control`}
                value={inputValue!}
                onChange={inputHandler}
                readOnly={readOnly}
            />
            {errors && <div className="invalid-tooltip">{errors}</div>}
        </div>
    );
};

export const DateInputText = (props: DateInputTextProps) => {
    const {title, inputValue, setInputValue, errors, readOnly, format = 'GG/MM/AAAA'} = props;

    const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const nd = moment(event.target.value)
        setInputValue(nd.isValid() ? nd.format('YYYY/MM/DD') : event.target.value);
    };

    return (
        <div className="position-relative form-group">
            <label>{title}</label>
            <input
                type='text'
                className={`${errors ? "is-invalid" : ""} form-control`}
                value={inputValue ? moment(inputValue).format('DD/MM/YYYY') : ''}
                onChange={inputHandler}
                readOnly={readOnly}
                placeholder={format}
            />
            {errors && <div className="invalid-tooltip">{errors}</div>}
        </div>
    );
};

export const FileInput = (props: FileInputProps) => {
    const {title, setInputValue, errors} = props;

    const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event);
    };

    return (
        <div className="position-relative form-group">
            <label>{title}</label>
            <input
                type='file'
                className={`${errors ? "is-invalid" : ""} form-control`}
                onChange={inputHandler}
            />
            {errors && <div className="invalid-tooltip">{errors}</div>}
        </div>
    );
};
