export const STATUS_LIST = [
    "VISIBILE",
    "NON VISIBILE",
]

export const FORMATION_LIST = [
    "Seleziona una categoria",
    "Formazione a distanza",
    "Formazione sul campo",
    "Formazione residenziale",
    "Formazione blended",
]

export const SUBJECT_LIST = [
    "Seleziona ambito",
    "1 − APPLICAZIONE NELLA PRATICA QUOTIDIANA DEI PRINCIPI E DELLE PROCEDURE DELL'EVIDENCE BASED PRACTICE (EBM - EBN - EBP)",
    "2 − LINEE GUIDA - PROTOCOLLI - PROCEDURE",
    "3 − DOCUMENTAZIONE CLINICA. PERCORSI CLINICO-ASSISTENZIALI DIAGNOSTICI E RIABILITATIVI, PROFILI DI ASSISTENZA - PROFILI DI CURA",
    "4 − APPROPRIATEZZA PRESTAZIONI SANITARIE NEI LEA. SISTEMI DI VALUTAZIONE, VERIFICA E MIGLIORAMENTO DELL'EFFICIENZA ED EFFICACIA",
    "5 − PRINCIPI, PROCEDURE E STRUMENTI PER IL GOVERNO CLINICO DELLE ATTIVITÀ SANITARIE",
    "6 − LA SICUREZZA DEL PAZIENTE. RISK MANAGEMENT",
    "7 − LA COMUNICAZIONE EFFICACE INTERNA, ESTERNA, CON PAZIENTE. LA PRIVACY ED IL CONSENSO INFORMATO",
    "8 − INTEGRAZIONE INTERPROFESSIONALE E MULTIPROFESSIONALE, INTERISTITUZIONALE",
    "9 − INTEGRAZIONE TRA ASSISTENZA TERRITORIALE ED OSPEDALIERA",
    "10 − EPIDEMIOLOGIA - PREVENZIONE E PROMOZIONE DELLA SALUTE CON ACQUISIZIONE DI NOZIONI TECNICO-PROFESSIONALI",
    "11 − MANAGEMENT SANITARIO. INNOVAZIONE GESTIONALE E SPERIMENTAZIONE DI MODELLI ORGANIZZATIVI E GESTIONALI (vedi nota 1)",
    "12 − ASPETTI RELAZIONALI (LA COMUNICAZIONE INTERNA, ESTERNA, CON PAZIENTE) E UMANIZZAZIONE DELLE CURE",
    "13 − METODOLOGIA E TECNICHE DI COMUNICAZIONE SOCIALE PER LO SVILUPPO DEI PROGRAMMI NAZIONALI E REGIONALI DI PREVENZIONE PRIMARIA",
    "14 − ACCREDITAMENTO STRUTTURE SANITARIE E DEI PROFESSIONISTI. LA CULTURA DELLA QUALITA'",
    "15 − MULTICULTURALITA' E CULTURA DELL' ACCOGLIENZA. NELL' ATTIVITÀ SANITARIA",
    "16 − ETICA, BIOETICA E DEONTOLOGIA",
    "17 − ARGOMENTI DI CARATTERE GENERALE: INFORMATICA E LINGUA INGLESE SCIENTIFICA DI LIVELLO AVANZATO. NORMATIVA IN MATERIA SANITARIA : I PRINCIPI ETICI E CIVILI DEL SSN",
    "18 − CONTENUTI TECNICO-PROFESSIONALI (CONOSCENZE E COMPETENZE) SPECIFICI DI CIASCUNA PROFESSIONE, DI CIASCUNA SPECIALIZZAZIONE E DI CIASCUNA ATTIVITÀ ULTRASPECIALISTICA. MALATTIE RARE",
    "19 − MEDICINE NON CONVENZIONALI: VALUTAZIONE DELL'EFFICACIA IN RAGIONE DEGLI ESITI E DEGLI AMBITI DI COMPLEMENTARIETA'",
    "20 − TEMATICHE SPECIALI DEL SSN E SSR ED A CARATTERE URGENTE e/o STRAORDINARIO INDIVIDUATE DALLA COMMISSIONALE NAZIONALE PER LA FORMAZIONE CONTINUA E DALLE REGIONl/PROVINCE AUTONOME PER FAR FRONTE A SPECIFICHE EMERGENZE SANITARIE CON ACQUISIZIONE DI NOZIONI DI TECNICO-PROFESSIONALI",
    "21 − TRATTAMENTO DEL DOLORE ACUTO E CRONICO. PALLIAZIONE",
    "22 − FRAGILITA' (MINORI, ANZIANI, TOSSICO-DIPENDENTI, SALUTE MENTALE) TUTELA DEGLI ASPETTI ASSISTENZIALI E SOCIO-ASSISTENZIALI",
    "23 − SICUREZZA ALIMENTARE E/O PATOLOGIE CORRELATE",
    "24 − SANITÀ VETERINARIA",
    "25 − FARMACO EPIDEMIOLOGIA, FARMACOECONOMIA, FARMACOVIGILANZA",
    "26 − SICUREZZA AMBIENTALE E/O PATOLOGIE CORRELATE",
    "27 − SICUREZZA NEGLI AMBIENTI E NEI LUOGHI DI LAVORO E PATOLOGIE CORRELATE",
    "28 − IMPLEMENTAZIONE DELLA CULTURA E DELLA SICUREZZA IN MATERIA DI DONAZIONE TRAPIANTO",
    "29 − INNOVAZIONE TECNOLOGICA: VALUTAZIONE, MIGLIORAMENTO DEI PROCESSI DI GESTIONE DELLE TECNOLOGIE BIOMEDICHE E DEI DISPOSITIVI MEDICI. HEALTH TECHNOLOGY ASSESSMENT",
    "30 − EPIDEMIOLOGIA - PREVENZIONE E PROMOZIONE DELLA SALUTE CON ACQUISIZIONE DI NOZIONI DI PROCESSO",
    "31 − EPIDEMIOLOGIA - PREVENZIONE E PROMOZIONE DELLA SALUTE CON ACQUISIZIONE DI NOZIONI DI SISTEMA",
    "32 − TEMATICHE SPECIALI DEL SSN E SSR ED A CARATTERE URGENTE e/o STRAORDINARIO INDIVIDUATE DALLA COMMISSIONALE NAZIONALE PER LA FORMAZIONE CONTINUA E DALLE REGIONI/PROVINCE AUTONOME PER FAR FRONTE A SPECIFICHE EMERGENZE SANITARIE CON ACQUISIZIONE DI NOZIONI DI PROCESSO",
    "33 − TEMATICHE SPECIALI DEL SSN E SSR ED A CARATTERE URGENTE e/o STRAORDINARIO INDIVIDUATE DALLA COMMISSIONALE NAZIONALE PER LA FORMAZIONE CONTINUA E DALLE REGIONI/PROVINCE AUTONOME PER FAR FRONTE A SPECIFICHE EMERGENZE SANITARIE CON ACQUISIZIONE DI NOZIONI DI SISTEMA",
]

export const CATEGORY_LIST = [
    "Seleziona tipologia formazione",
    "Formazione residenziale classica(1-200 partecipanti",
    " Convegno / Congresso (oltre 200 partecipanti)",
    "Convegno / Congresso (oltre 200 partecipanti) con valutazione apprendimento (2010-2016)",
    "Workshop, seminari, corsi teorici (entro i 100 partecipanti) che si svolgono all’interno di congressi e convegni.",
    "Formazione residenziale interattiva (2010-2016)",
    "Training individualizzato",
    "Gruppi di miglioramento",
    "Attività di ricerca",
    "Audit clinico e/o assistenziale (2010-2016)",
    "FAD senza tutoraggio (2010-2016)",
    "FAD con tutoraggio (2010-2016)",
    "FAD con strumenti informatici/cartacei",
    "FAD E-Learning",
    "FAD Sincrona",
    "Videoconferenza",

]

export const PDF = {
    "signList": "Scheda Firme",
    "partecipant": "Scheda Partecipante",
    "certificateFree": "Attestato Partecipazione",
    "certificateSocial": "Attestato Sociale",
    "cetificateStudent": "Attestato discente",
    "XML": "XML",
}

export const formatPrice = (price: number) => {
    return price && price > 0 ? "€ " + price.toFixed(2) : "Gratuito";
}
