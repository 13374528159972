import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import "./style.css";
import LoginForm from "./loginForm";
import ResetPasswordForm from "./resetPasswordForm";

const Login = () => {

    const [recover, setRecover] = useState(false);

    let {isLoggedIn} = useSelector((state) => state.auth);

    const navigate = useNavigate();

    if (isLoggedIn) {
        return navigate("/");
    }

    return (<div className="login-body">
        <div className="container" style={{maxWidth: "500px"}}>
            <div className="login-box">
                <div className="col-12 login-key text-center">
                    <i className="pe-7s-user"></i>
                </div>
                <div className="col-12  text-center">PANNELLO APSI</div>
                <div className="col-12 p-5">
                    {recover ?
                        <ResetPasswordForm onBackToLogin={() => setRecover(false)}/> :
                        <LoginForm onRecoverClicked={() => setRecover(true)}/>
                    }
                </div>
            </div>
        </div>
    </div>);
};

export default Login;
