import React, {Fragment} from 'react';
import cx from 'classnames';

import Nav from '../AppNav/VerticalNavWrapper';

import PerfectScrollbar from 'react-perfect-scrollbar';
import HeaderLogo from '../AppLogo';
import {useDispatch, useSelector} from 'react-redux';
import {setEnableMobileMenu} from '../../reducers/theme';
import {useSearchParams} from 'react-router-dom';

const AppSidebar = () => {

    const dispatch = useDispatch()
    const [searchParams] = useSearchParams();

    const enableBackgroundImage = useSelector((state) => state.theme);
    const backgroundImage = useSelector((state) => state.theme);
    const backgroundImageOpacity = useSelector((state) => state.theme);
    const enableMobileMenu = useSelector((state) => state.theme);

    const toggleMobileSidebar = () => {
        dispatch(setEnableMobileMenu(!enableMobileMenu))
    }

    if (searchParams.get("public") === 1 || parseInt(searchParams.get('layout')) === 0) {
        return null;
    }

    return (
        <Fragment>
            <div className="sidebar-mobile-overlay" onClick={toggleMobileSidebar}/>
            <div
                className={"app-sidebar bg-royal sidebar-text-light sidebar-shadow appear-done enter-done"}
                // appear={true} timeout={1500} enter={false} exit={false}
            >
                <div>
                    <HeaderLogo/>
                    <PerfectScrollbar>
                        <div className="app-sidebar__inner">
                            <Nav/>
                        </div>
                    </PerfectScrollbar>
                    <div
                        className={cx("app-sidebar-bg", backgroundImageOpacity)}
                        style={{
                            backgroundImage: enableBackgroundImage ? 'url(' + backgroundImage + ')' : null
                        }}>
                    </div>
                </div>
            </div>
        </Fragment>
    )

}

// const mapStateToProps = state => ({
//     enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
//     enableSidebarShadow: state.ThemeOptions.enableSidebarShadow,
//     enableMobileMenu: state.ThemeOptions.enableMobileMenu,
//     backgroundColor: state.ThemeOptions.backgroundColor,
//     backgroundImage: state.ThemeOptions.backgroundImage,
//     backgroundImageOpacity: state.ThemeOptions.backgroundImageOpacity,
// });

// const mapDispatchToProps = dispatch => ({

//     setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),

// });

export default AppSidebar;