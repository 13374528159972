import React from "react";

const Errors = ({type, message}) => {
    const textClassName = `text-${type === "error" ? "danger" : "warning"}`;
    return (
        <div>
            <p className={textClassName}>{message}</p>
        </div>
    );
};

export default Errors;
