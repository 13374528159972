import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getXMLContent} from "../../../services/course.service";

const ShowXML = () => {
    const {id} = useParams();

    const [content, setContent] = useState();

    useEffect(() => {
        getXMLContent(id).then((response) => setContent(response.data.data));
    }, []);

    return <div>{content}</div>
};

export default ShowXML;
