import React, {Fragment} from 'react';
import {useSearchParams} from 'react-router-dom';

import {CSSTransition, TransitionGroup} from 'react-transition-group';

import HeaderLogo from '../AppLogo';

import SearchBox from './Components/SearchBox';
import UserBox from './Components/UserBox';

const Header = () => {

    const [searchParams] = useSearchParams();

    if (searchParams.get("public") === 1 || parseInt(searchParams.get('layout')) === 0) {
        return null;
    }

    return (
        <Fragment>
            <TransitionGroup>
                <CSSTransition
                    component="div"
                    className={"app-header bg-strong-bliss header-text-light header-shadow appear-done enter-done"}
                    appear={true}
                    timeout={1500}
                    enter={false}
                    exit={false}>
                    <div>

                        <HeaderLogo/>

                        <div className={"app-header__content"}>
                            <div className="app-header-left">
                                <SearchBox/>
                            </div>
                            <div className="app-header-right">
                                <UserBox/>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </Fragment>
    );

}

export default Header;