import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import "./style.css";

import {loginAction} from "../../../actions/auth";
import Errors from "../errors/index";

const LoginForm = ({onRecoverClicked}) => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [checkBtn, setCheckBtn] = useState(false);
    const [loading, setLoading] = useState(false);

    let {errorMessage} = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const handleLogin = (e) => {
        e.preventDefault();

        setLoading(true);

        dispatch(loginAction(username, password))
            .then(() => {
                navigate("/");
            })
            .catch(() => {
                setLoading(false);
            });
    };

    return <form onSubmit={handleLogin}>
        <div className="form-group">
            <label htmlFor="username" className="form-control-label">
                USERNAME
            </label>
            <input
                type="text"
                className="form-control"
                name="username"
                value={username}
                onChange={onChangeUsername}
            />
        </div>

        <div className="form-group">
            <label htmlFor="password" className="form-control-label">
                PASSWORD
            </label>
            <input
                type="password"
                className="form-control"
                name="password"
                value={password}
                onChange={onChangePassword}
            />
        </div>

        <div className="form-group">
            <input
                type="checkbox"
                checked={checkBtn}
                onChange={() => setCheckBtn(!checkBtn)}
            />
            <label htmlFor="password" className="form-control-label">
                Rimani collegato
            </label>
        </div>

        <span onClick={onRecoverClicked}>Non ricordi la password? </span>

        <div className="col-lg-12 loginbttm">
            <div className="col-lg-12 login-btm login-text">
                {errorMessage ? (
                    <Errors type="error" message={errorMessage}/>
                ) : (
                    ""
                )}
            </div>

            <div className="col-lg-6 login-btm login-button">
                <button
                    className="btn btn-outline-primary"
                    disabled={loading}
                >
                    {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Login</span>
                </button>
            </div>

            <div className="clearfix"/>
        </div>
    </form>
};

export default LoginForm;
