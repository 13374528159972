import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import getAuthError from "../../reducers/selectors/getAuthError";

import {loginAction, logoutAction} from "../../actions/auth";
import {UserModel} from "../../@types/model";
import getUser from "../../reducers/selectors/getUser";

const LoginForm = () => {

    const user: undefined | UserModel = useSelector(getUser);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    let errorMessage: string = useSelector(getAuthError);

    const dispatch: any = useDispatch();

    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const handleLogin = (e) => {
        e.preventDefault();

        setLoading(true);

        dispatch(loginAction(username, password))
            .then(() => {
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    if (user) {
        return <div className='text-center'>
            Non sei tu?<br/>
            <button
                className="btn btn-outline-primary"
                onClick={() => dispatch(logoutAction())}
            >
                <span>Logout</span>
            </button>
        </div>
    }

    return <form className='form-inline' onSubmit={handleLogin}>
        <div className="form-group">
            <label htmlFor="username" className="form-control-label me-2">
                USERNAME
            </label>
            <input
                type="text"
                className="form-control"
                name="username"
                value={username}
                onChange={onChangeUsername}
            />
        </div>

        <div className="form-group">
            <label htmlFor="password" className="form-control-label mx-2">
                PASSWORD
            </label>
            <input
                type="password"
                className="form-control"
                name="password"
                value={password}
                onChange={onChangePassword}
            />
        </div>

        {/*<div className='col-12 mt-4 text-center'>*/}
        {/*    <span>Non ricordi la password? </span>*/}
        {/*</div>*/}

        <div className="col-12 mt-2 text-center">
            <div className="col-12 login-text">
                {errorMessage}
            </div>

            <div className="col-12 login-button">
                <button
                    className="btn btn-outline-primary"
                    disabled={loading}
                >
                    {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Login</span>
                </button>
            </div>

            <div className="clearfix"/>
        </div>
    </form>
};

export default LoginForm;
