import React, {Fragment, useEffect, useState} from "react";
import {DateInput, Input, Select} from "../../../components/Inputs";
import {HtmlInputForm} from "../../../components/RichTextEditor";
import RichTextEditor from "react-rte";
import {ThumbnailImage} from "../../../components/ThumbnailImage";
import "./style.css";
import {createNewCourse, editCourse, getCourseById, uploadImage,} from "../../../services/course.service";
import {useNavigate, useParams} from "react-router-dom";

import {Bounce, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {CATEGORY_LIST, FORMATION_LIST, STATUS_LIST, SUBJECT_LIST} from "../../../constants/course";
import {CategoriesTags} from "../../../components/Inputs/tags";

const CreateCourse = () => {

    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();

    const [errors, setErrors] = useState({});

    const [isLoading, setIsLoading] = useState(false);

    const [title, setTitle] = useState("");
    const [abstract, setAbstract] = useState("");
    const [codice, setCodice] = useState(0);
    const [edizione, setEdizione] = useState(0);
    const [luogo, setLuogo] = useState("");
    const [sede, setSede] = useState("");
    const [dataInizioCorso, setDataInizioCorso] = useState<string | null>("");
    const [dataFineCorso, setDataFineCorso] = useState<string | null>("");
    const [inizioIscrizioni, setInizioIscrizioni] = useState<string | null>("");
    const [fineIscrizioni, setFineIscrizioni] = useState<string | null>("");
    const [creditiProfSanitarie, setCreditiProfSanitarie] = useState(0);
    const [creditiProfSociali, setCreditiProfSociali] = useState(0);
    const [prezzo, setPrezzo] = useState(0);
    const [posti, setPosti] = useState(0);
    const [ore, setOre] = useState(0);
    const [password, setPassword] = useState("");
    const [stato, setStato] = useState("");
    const [descrRTE, setDescrRTE] = useState<RichTextEditor>(
        RichTextEditor.createEmptyValue()
    );
    const [thumbnailImage, setThumbnailImage] = useState("");
    const [tipoFormazione, setTipoFormazione] = useState("");
    const [codiceObi, setCodiceObi] = useState("");
    const [codiceTipologia, setCodiceTipologia] = useState("");

    const [starred, setStarred] = useState<number>(0);
    const [category, setCategory] = useState([]);
    const [tags, setTags] = useState("");

    useEffect(() => {
        if (!id) return
        setIsLoading(true);
        getCourseById(parseInt(id)).then((response: any) => {
            setThumbnailImage(response.data.data.thumbnail!)
            setDescrRTE(RichTextEditor.createValueFromString(response.data.data.description, 'html'))
            setTitle(response.data.data.name!);
            setAbstract(response.data.data.abstract!);
            setCodice(response.data.data.code!);
            setEdizione(response.data.data.edition!);
            setLuogo(response.data.data.place!);
            setSede(response.data.data.location!);
            setDataInizioCorso(response.data.data.date_start!);
            setDataFineCorso(response.data.data.date_end!);
            setInizioIscrizioni(response.data.data.registration_from!);
            setFineIscrizioni(response.data.data.registration_to!);
            setCreditiProfSanitarie(response.data.data.credits_san!);
            setCreditiProfSociali(response.data.data.credits_soc!);
            setPrezzo(response.data.data.price!);
            setPosti(response.data.data.seats!);
            setOre(response.data.data.hours!);
            setStato(response.data.data.status!);
            setStarred(response.data.data.home_starred);
            setCategory(response.data.data.home_categories);
            setTags(response.data.data.home_tag);
            setPassword(response.data.data.password)
            setTipoFormazione(response.data.data.formation)
            setCodiceObi(response.data.data.subject)
            setCodiceTipologia(response.data.data.category)
            setIsLoading(false);
        });
    }, [id]);


    const onFormValidate = () => {
        let errors = {}
        if (title.length === 0) {
            errors['title'] = "Questo campo è obligatorio";
        }
        if (abstract.length === 0) {
            errors['abstract'] = "Questo campo è obligatorio";
        }
        if (!codice || codice === 0) {
            errors['code'] = "Questo campo è obligatorio";
        }
        if (!edizione || edizione === 0) {
            errors['edition'] = "Questo campo è obligatorio";
        }
        if (luogo.length === 0) {
            errors['place'] = "Questo campo è obligatorio";
        }
        if (sede.length === 0) {
            errors['location'] = "Questo campo è obligatorio";
        }
        if (!dataInizioCorso) {
            errors['date_start'] = "Questo campo è obligatorio";
        }
        if (!dataFineCorso) {
            errors['date_end'] = "Questo campo è obligatorio";
        }
        return errors
    };

    const notifyError = (error) => toast("Form non valido: " + error, {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'bottom-center',
        type: 'error'
    });

    const notify2 = () => toast("Corso " + (id ? "aggiornato" : "salvato") + " con successo", {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'bottom-center',
        type: 'success'
    });

    const onImageSelected = (thumbnailImage) => {
        let formData = new FormData();
        formData.append("file", thumbnailImage);
        uploadImage(formData).then((response: any) => {
            setThumbnailImage(response.data.data.filename)
        })
    }

    const onFormSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault();
        const errors = onFormValidate()
        setErrors(errors)
        if (Object.keys(errors).length) {
            setTimeout(() => setErrors({}), 10000)
            return
        }
        const course = {
            name: title,
            abstract: abstract,
            description: descrRTE.toString('html'),
            category: Number(codiceTipologia),
            code: Number(codice),
            edition: Number(edizione),
            status: Number(stato),
            registration_from: inizioIscrizioni?.toString(),
            registration_to: fineIscrizioni?.toString(),
            place: luogo,
            location: sede,
            price: prezzo,
            payment: null,
            thumbnail: thumbnailImage,
            seats: posti,
            subject: codiceObi,
            formation: tipoFormazione,
            hours: ore,
            date_start: dataInizioCorso?.toString(),
            date_end: dataFineCorso?.toString(),
            credits_san: creditiProfSanitarie,
            credits_soc: creditiProfSociali,
            password: password,
            home_starred: starred,
            home_category: category,
            home_tag: tags
        }

        if (id) {
            editCourse(course, id)
                .then(() => {
                    notify2()
                    navigate(`/course`)
                })
                .catch(({response}) => {
                    notifyError(response.data.error.message)
                    setErrors(response.data.data)
                });
        } else {
            createNewCourse(course)
                .then(() => {
                    notify2()
                    navigate(`/course`)
                })
                .catch(({response}) => {
                    setErrors(response.data.data)
                    setTimeout(() => setErrors({}), 10000)
                    notifyError(response.data.error.message)
                });
        }

    };
    return (
        <Fragment>
            <div className="couses-list-title-container">Crea nuovo corso</div>
            {isLoading ? (
                <div className="loading-container">Loading...</div>
            ) : (
                <form onSubmit={onFormSubmit} className="py-4">
                    <div className="container">
                        <ThumbnailImage setFile={onImageSelected} file={thumbnailImage}/>
                        <div className="col-6">
                            <Input
                                inputValue={title}
                                setInputValue={setTitle}
                                type={"text"}
                                title={"Titolo (richiesto)"}
                                placeholder={"scrivi qui..."}
                                errors={errors['title']}
                            />
                        </div>
                        <HtmlInputForm
                            descrRTE={descrRTE}
                            setDescrRTE={setDescrRTE}
                        />
                        <div className="row">
                            <div className="col-6">
                                <Input
                                    inputValue={abstract}
                                    setInputValue={setAbstract}
                                    type={"text"}
                                    title={"Abstract (richiesto)"}
                                    placeholder={"scrivi qui..."}
                                    errors={errors['abstract']}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Input
                                    inputValue={codice}
                                    setInputValue={setCodice}
                                    type={"number"}
                                    title={"Codice (richiesto)"}
                                    placeholder={"scrivi qui..."}
                                    errors={errors['code']}
                                />
                            </div>
                            <div className="col-6">
                                <Input
                                    inputValue={edizione}
                                    setInputValue={setEdizione}
                                    type={"number"}
                                    title={"Edizione (richiesto)"}
                                    placeholder={"scrivi qui..."}
                                    errors={errors['edition']}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Input
                                    inputValue={luogo}
                                    setInputValue={setLuogo}
                                    type={"text"}
                                    title={"Luogo (richiesto)"}
                                    placeholder={"scrivi qui..."}
                                    errors={errors['place']}
                                />
                            </div>
                            <div className="col-6">
                                <Input
                                    inputValue={sede}
                                    setInputValue={setSede}
                                    type={"text"}
                                    title={"Sede (richiesto)"}
                                    placeholder={"scrivi qui..."}
                                    errors={errors['location']}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <DateInput
                                    inputValue={dataInizioCorso}
                                    setInputValue={setDataInizioCorso}
                                    type={"date"}
                                    title={"Data inizio corso"}
                                    errors={errors['date_start']}
                                />
                            </div>
                            <div className="col-6">
                                <DateInput
                                    inputValue={dataFineCorso}
                                    setInputValue={setDataFineCorso}
                                    type={"date"}
                                    title={"Data fine corso"}
                                    errors={errors['date_end']}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <DateInput
                                    inputValue={inizioIscrizioni}
                                    setInputValue={setInizioIscrizioni}
                                    type={"date"}
                                    title={"Inizio iscrizioni"}
                                    errors={errors['registration_from']}
                                />
                            </div>
                            <div className="col-6">
                                <DateInput
                                    inputValue={fineIscrizioni}
                                    setInputValue={setFineIscrizioni}
                                    type={"date"}
                                    title={"Fine iscrizioni"}
                                    errors={errors['registration_to']}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Input
                                    inputValue={creditiProfSanitarie}
                                    setInputValue={setCreditiProfSanitarie}
                                    type={"number"}
                                    title={"Crediti (prof. sanitarie)"}
                                    placeholder={"scrivi qui..."}
                                    errors={errors['credits_san']}
                                />
                            </div>
                            <div className="col-6">
                                <Input
                                    inputValue={creditiProfSociali}
                                    setInputValue={setCreditiProfSociali}
                                    type={"number"}
                                    title={"Crediti (prof. sociali)"}
                                    placeholder={"scrivi qui..."}
                                    errors={errors['credits_soc']}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Input
                                    inputValue={prezzo}
                                    setInputValue={setPrezzo}
                                    type={"number"}
                                    title={"Prezzo (€)"}
                                    placeholder={"scrivi qui..."}
                                    errors={errors['price']}
                                />
                            </div>
                            <div className="col-6">
                                <Input
                                    inputValue={posti}
                                    setInputValue={setPosti}
                                    type={"number"}
                                    title={"Posti"}
                                    placeholder={"scrivi qui..."}
                                    errors={errors['seats']}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Select
                                    title={"Stato"}
                                    inputValue={stato}
                                    setInputValue={setStato}
                                    list={STATUS_LIST}
                                    errors={errors['']}
                                />
                            </div>
                            <div className="col-6">
                                <Input
                                    inputValue={ore}
                                    setInputValue={setOre}
                                    type={"number"}
                                    title={"Ore"}
                                    placeholder={"scrivi qui..."}
                                    errors={errors['hours']}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Select
                                    title={"Tipo Formazione"}
                                    inputValue={tipoFormazione}
                                    setInputValue={setTipoFormazione}
                                    list={FORMATION_LIST}
                                    errors={errors['formation']}
                                />
                            </div>
                            <div className="col-6">
                                <Select
                                    title={"Codice Obi"}
                                    inputValue={codiceObi}
                                    setInputValue={setCodiceObi}
                                    list={SUBJECT_LIST}
                                    errors={errors['subject']}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Select
                                    title={"Codice Tipologia Formazione"}
                                    inputValue={codiceTipologia}
                                    setInputValue={setCodiceTipologia}
                                    list={CATEGORY_LIST}
                                    errors={errors['category']}
                                />
                            </div>
                            <div className="col-6">
                                <Input
                                    inputValue={password}
                                    setInputValue={setPassword}
                                    type={"text"}
                                    title={"Password"}
                                    placeholder={"scrivi qui..."}
                                    errors={errors['password']}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">WORDPRESS</div>
                            <div className="col-2">
                                <Select
                                    title={"Show in home"}
                                    inputValue={starred}
                                    setInputValue={setStarred}
                                    list={['NO', 'SI']}
                                    errors={errors['home_starred']}
                                />
                            </div>
                            <div className="col-5">
                                <CategoriesTags
                                    title={"Category"}
                                    onChange={setCategory}
                                    tags={category}
                                />
                            </div>
                            <div className="col-5">
                                <Select
                                    title={"Tags"}
                                    inputValue={tags}
                                    setInputValue={setTags}
                                    list={[]}
                                    errors={errors['home_tag']}
                                />
                            </div>
                        </div>

                        <button className="btn btn-primary" type="submit">
                            {id ? "Modifica Corso" : "Crea Corso"}
                        </button>
                    </div>
                </form>
            )}
        </Fragment>
    );
};

export default CreateCourse;
