import React, {Fragment, useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Button, Input} from "reactstrap";
import {getVouchersToCourse} from "../../../services/course.service";
import "./style.css"

export default function CourseVouchers() {

    const {id} = useParams();

    const [vouchers, setVouchers] = useState([]);
    const [filterValue, setFilterValue] = useState<any>();
    const [filterVouchers, setFilterVouchers] = useState<any>();

    useEffect(() => {
        getVouchersToCourse(parseInt(id ?? "0")).then((response: any) => setVouchers(response.data.data));
    }, []);

    const rimuoviHandler = useCallback(() => {
    }, []);

    useEffect(() => {
        if (vouchers !== undefined && filterValue === undefined) {
            setFilterVouchers(vouchers)
        } else if (vouchers !== undefined) {
            setFilterVouchers(
                vouchers.filter((item: any) => {
                    return item.reservation_code.toLowerCase().includes(filterValue);
                })
            );
        }

    }, [vouchers, filterValue]);

    return (
        <Fragment>
            <div className="couses-list-title-container">Lista voucher</div>
            <div className="voucher-generati-container">
                <div className="d-flex justify-content voucher-Header">
                    <div className="mb-2 mr-sm-2 mb-sm-0 searchInput">
                        <Input
                            placeholder="Enter value"
                            onChange={(e) => setFilterValue(e.target.value)}
                        />
                    </div>
                </div>
                <div className="userBtn">
                    <div className="voucher-generati-title">Vaucher generati</div>
                    <Button
                        color="primary"
                        className="voucher-generati-button"
                        onClick={rimuoviHandler}
                    >
                        Rimuovi
                    </Button>
                </div>
                <div className="table-container">
                    <table className="table">
                        <thead className="table-head">
                        <tr className="table-row">
                            <th className="th th-1">
                                <input type={"checkbox"}/>
                            </th>
                            <th className="th th-2">#</th>
                            <th className="th th-2">Reservation Code</th>
                            <th className="th th-3">Value</th>
                            <th className="th th-4">inDate</th>
                            <th className="th th-5">usDate</th>
                        </tr>
                        </thead>

                        <tbody className="table-body">
                        {filterVouchers.map((item: any, index: number) => {
                            return (
                                <tr key={index} className="table-row hover-grey">
                                    <td className="td td-1">
                                        <input
                                            type={"checkbox"}
                                            checked={false}
                                            onChange={() => {
                                            }}
                                        />
                                    </td>
                                    <td>{index}</td>
                                    <td className="td td-2">{item.reservation_code}</td>
                                    <td className="td td-3">{item.value}</td>
                                    <td className="td td-4">{item.indt}</td>
                                    <td className="td td-5">{item.usdt}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </Fragment>
    );
}
