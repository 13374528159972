import React, {Fragment, useEffect, useState} from "react";
import {getSubscriptionToCourse, setSubscriptionParticipantToCourse, setSubscriptionStatusToCourse} from "../../../services/course.service";
import {Button} from "reactstrap";
import "./style.css"
import {useNavigate, useParams} from "react-router-dom";
import {SubscriptionModel} from "../../../@types/model";
import {PARTICIPANT_LABEL, PROFESSION, SUBSCRIPTION_LABEL, SubscriptionParticipation, SubscriptionStatus} from "../../../constants/subscription";
import {Bounce, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {PDF} from "../../../constants/course";
import {Modal} from "react-bootstrap";

export default function CourseUsers() {

    const {id} = useParams<{ id: string }>();

    const [users, setUsers] = useState<SubscriptionModel[]>([]);
    const [selections, setSelections] = useState<number[]>([]);
    const [pdfLayout, setPdfLayout] = useState("");

    const [details, setDetails] = useState<SubscriptionModel | null>(null);

    const navigate = useNavigate();

    const loadUsers = () => {
        getSubscriptionToCourse(parseInt(id ?? "0")).then((response: any) => setUsers(response.data.data));
    }

    useEffect(() => {
        loadUsers()
    }, []);

    const notifyError = (error) => toast("C'è stato un errore durante l'operazione: " + error, {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'bottom-center',
        type: 'error'
    });

    const notifySuccess = (message: string) => toast(message, {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'bottom-center',
        type: 'success'
    });

    return (
        <Fragment>
            <div className="couses-list-title-container">Lista utenti</div>
            <div className="utenti-confermati-container">
                <div className="userBtn">
                    <Button
                        color="primary"
                        onClick={() => {
                            if (selections.length === 0) {
                                alert("Selezione non valida")
                                return
                            }
                            setSubscriptionStatusToCourse(parseInt(id!), selections, SubscriptionStatus.CANCELLED)
                                .then(() => {
                                    notifySuccess("Utente aggiornato correttamente")
                                    setSelections([])
                                    loadUsers()
                                })
                                .catch(({response}) => {
                                    notifyError(response.data.error.message)
                                });
                        }}
                    >
                        Cancella Prenotazione
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            if (selections.length === 0) {
                                alert("Selezione non valida")
                                return
                            }
                            setSubscriptionStatusToCourse(parseInt(id!), selections, SubscriptionStatus.CONFIRMED)
                                .then(() => {
                                    notifySuccess("Partecipazione aggiornata correttamente")
                                    setSelections([])
                                    loadUsers()
                                })
                                .catch(({response}) => {
                                    notifyError(response.data.error.message)
                                });
                        }}
                    >
                        Conferma Prenotazione
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            if (selections.length === 0) {
                                alert("Selezione non valida")
                                return
                            }
                            setSubscriptionParticipantToCourse(parseInt(id!), selections, SubscriptionParticipation.STATUS_PRESENT)
                                .then(() => {
                                    notifySuccess("Utente aggiornato correttamente")
                                    setSelections([])
                                    loadUsers()
                                })
                                .catch(({response}) => {
                                    notifyError(response.data.error.message)
                                });
                        }}
                    >
                        Utente Partecipante
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            if (selections.length === 0) {
                                alert("Selezione non valida")
                                return
                            }
                            setSubscriptionParticipantToCourse(parseInt(id!), selections, SubscriptionParticipation.STATUS_ABSENT)
                                .then(() => {
                                    notifySuccess("Partecipazione aggiornata correttamente")
                                    setSelections([])
                                    loadUsers()
                                })
                                .catch(({response}) => {
                                    notifyError(response.data.error.message)
                                });
                        }}
                    >
                        Utente Assente
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            if (selections.length !== 1) {
                                alert("Selezione non valida")
                                return
                            }
                            navigate(`/course/subscription/${selections[0]}/update`)
                        }}
                    >
                        Aggiorna dati Utente
                    </Button>
                    <select
                        value={pdfLayout}
                        onChange={(e) => setPdfLayout(e.target.value)}
                        title={"PDF"}
                    >
                        <option>Seleziona</option>
                        {Object.keys(PDF).map(id => <option key={id} value={id}>{PDF[id]}</option>)}
                    </select>
                    <Button
                        color="primary"
                        onClick={() => {
                            if (selections.length === 0) {
                                alert("Selezione non valida")
                                return
                            }
                            window.open(`/course/${id}/download/${pdfLayout}?users=${selections}&layout=0`, '_blank')
                        }}
                    >
                        Genera PDF
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => window.open(`/course/${id}/xml?layout=0`, '_blank')}
                    >
                        Genera XML
                    </Button>
                </div>
                {/* table */}
                <div className="table-container">
                    <table className="table">
                        <thead className="table-head">
                        <tr className="table-row">
                            <th className="th th-1">
                                <input
                                    type={"checkbox"}
                                    checked={selections.length === users.length}
                                    onClick={() => {
                                        setSelections((selections.length === users.length) ? [] : users.map(u => u.id))
                                    }}
                                />
                            </th>
                            <th className="th th-3">Name</th>
                            <th className="th th-3">Surname</th>
                            <th className="th th-4">Email</th>
                            <th className="th th-4">Professione</th>
                            <th className="th th-4">Ente di apparte.</th>
                            <th className="th th-4">Prenotazione</th>
                            <th className="th th-4">Partecipante</th>
                            <th className="th th-4">Pagato</th>
                            <th className="th th-5">Indirizzo</th>
                            <th className="th th-5">Città</th>
                            <th className="th th-5">Provincia</th>
                            <th className="th th-5">Fiscal code</th>
                            <th className="th th-5">Date</th>
                        </tr>
                        </thead>

                        <tbody className="table-body">
                        {users.map((item, index) => {
                            return (
                                <tr key={index} className="table-row hover-grey">
                                    <td className="td td-1">
                                        <input
                                            type={"checkbox"}
                                            checked={selections.includes(item.id)}
                                            onChange={() => {
                                                let newSelections;
                                                if (selections.includes(item.id)) {
                                                    newSelections = selections.filter(i => i !== item.id)
                                                } else {
                                                    newSelections = [...selections, item.id]
                                                }
                                                setSelections(newSelections)
                                            }}
                                        />
                                    </td>
                                    <td className="td td-3">
                                        <a href='javascript:void(0)' onClick={() => setDetails(item)}>{item.name}</a>
                                    </td>
                                    <td className="td td-4">{item.surname}</td>
                                    <td className="td td-4">{item.email}</td>
                                    <td className="td td-4">{PROFESSION[item.profession]}</td>
                                    <td className="td td-5">{item.organization}</td>
                                    <td className="td td-5">{SUBSCRIPTION_LABEL[item.status]}</td>
                                    <td className="td td-5">{PARTICIPANT_LABEL[item.participant]}</td>
                                    <td className="td td-5">{item.receipt && <a
                                        href={item.receipt}
                                        target="_blank"
                                        rel="noreferrer"
                                    >Ricevuta</a>}</td>
                                    <td className="td td-5">{item.address}</td>
                                    <td className="td td-5">{item.city}</td>
                                    <td className="td td-5">{item.province}</td>
                                    <td className="td td-5">{item.fiscal_code}</td>
                                    <td className="td td-5">{item.indt}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                    <Modal show={details !== null}>
                        <Modal.Header closeButton>
                            <Modal.Title>Dettagli prenotazione</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <label className='fw-bold'>Name:</label> {details?.name}<br/>
                            <label className='fw-bold'>Surname:</label> {details?.surname}<br/>
                            <label className='fw-bold'>Email:</label> {details?.email}<br/>
                            <label className='fw-bold'>Professione:</label> {PROFESSION[details?.profession || 0]}<br/>
                            <label className='fw-bold'>Ente di appartenenza:</label> {details?.organization}<br/>
                            <label
                                className='fw-bold'>Prenotazione:</label> {SUBSCRIPTION_LABEL[details?.status || 0]}<br/>
                            <label
                                className='fw-bold'>Partecipante:</label> {PARTICIPANT_LABEL[details?.participant || 0]}<br/>
                            <label className='fw-bold'>Pagato:</label> {details?.receipt ? "SI" : "NO"}<br/>
                            <label className='fw-bold'>Città:</label> {details?.city}<br/>
                            <label className='fw-bold'>Provincia:</label> {details?.province}<br/>
                            <label className='fw-bold'>Fiscal code:</label> {details?.fiscal_code}<br/>
                            <label className='fw-bold'>Date:</label> {details?.indt}<br/>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setDetails(null)}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </Fragment>
    );
}
