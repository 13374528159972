import axios from "axios";
import authHeader from "./auth-header";
import {SubscriptionModel} from "../@types/model";


export const createNewCourse = async (body: object) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/course/create`, body, {headers: authHeader()});
}

export const uploadImage = async (body: object) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/course/upload`, body, {headers: authHeader()});
}

export const editCourse = async (body: object, id: string) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/course/${id}/update`, body, {headers: authHeader()});
}

export const getCourseById = async (courseId: number) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/course/${courseId}/view`, {headers: authHeader()});
}

export const getCourseByCode = async (courseId: number) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/course/${courseId}/get`);
}

export const getCourses = async (filters = {}) => {
    let queryString = Object.keys(filters)
        .filter((key) => filters[key] !== null)
        .map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(filters[key])
        }).join('&');
    return await axios.get(`${process.env.REACT_APP_API_URL}/course?${queryString}`);
}

export const subscribeToCourse = async (courseId: number, body: UserRegistrationForm) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/course/${courseId}/subscribe`, body);
}

export const uploadSubscriptionReceipt = async (courseId: number, body: object) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/course/${courseId}/receipt`, body, {headers: authHeader()});
}

export const getSubscriptionToCourse = async (courseId: number) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/course/${courseId}/users`, {headers: authHeader()});
}

export const setSubscriptionStatusToCourse = async (courseId: number, subscriptions: number[], status: number) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/course/${courseId}/subscription-status`,
        {status, subscriptions}, {headers: authHeader()});
}

export const setSubscriptionParticipantToCourse = async (courseId: number, subscriptions: number[], participant: number) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/course/${courseId}/subscription-participant`,
        {participant, subscriptions}, {headers: authHeader()});
}

export const updateSubscription = async (courseId: number, subscription: any) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/course/${courseId}/update-subscription`, {
        headers: authHeader(),
        data: subscription
    });
}

export const getSubscriptionStatus = async (courseId: number): Promise<SubscriptionModel> => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/course/${courseId}/subscription`, {headers: authHeader()});
}

export const getLastSubscription = async (): Promise<SubscriptionModel> => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/course/last-subscription`, {headers: authHeader()});
}

export const getTeachersToCourse = async (id: number) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/course/${id}/teachers`, {headers: authHeader()});
}

export const getVouchersToCourse = async (id: number) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/course/${id}/vouchers`, {headers: authHeader()});
}

export const getUsersPdfByCourse = async (id: number, users: number[], template: string) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/course/${id}/generate-pdf`,
        {
            users,
            template
        }, {headers: authHeader()});
}

export const getXMLContent = async (id: number) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/course/${id}/generate-xml`, {headers: authHeader()});
};