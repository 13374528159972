import React, {Fragment, useCallback, useEffect, useState} from "react";
import {getUsers} from "../../../services/user.service";
import "./style.css";
import {useLocation} from "react-router-dom";

export default function Users() {

    const location = useLocation();
    const page = parseInt(location.hash.replace('#page=', ''))

    const [users, setUsers] = useState([]);
    const [pagination, setPagination] = useState({
        page: isNaN(page) ? 1 : page,
        total: 0
    })

    useEffect(() => {
        loadUsers()
    }, []);

    const loadUsers = (page = 1) => {
        getUsers(page).then((response: any) => {
            setUsers(response.data.data.items)
            setPagination(response.data.data.pagination)
        });
    }

    const getPagination = useCallback(() => {
        const pages: JSX.Element[] = []
        const size = 10
        const start = pagination.page > 5 ? pagination.page - 5 : pagination.page;
        for (let i = 0; i <= Math.min(pagination.total, size); i++) {
            pages.push(<li className='page-item' key={`item-${i}`}>
                <a
                    className={'page-link'}
                    href={'#page=' + (start + i)}
                    onClick={() => {
                        setPagination(Object.assign({}, pagination, {page: (start + i)}))
                        loadUsers(start + i)
                    }}>{start + i}</a>
            </li>)
        }
        return pages
    }, [pagination])

    return <Fragment>
        <div className="couses-list-title-container">User list</div>
        <div className="table-container">
            <table className="table">
                <thead className="table-head">
                <tr className="table-row">
                    <th className="th th-3">Nome</th>
                    <th className="th th-3">Username</th>
                    <th className="th th-4">Email</th>
                    <th className="th th-5">DOB</th>
                    <th className="th th-5">Fiscal code</th>
                    <th className="th th-5">Date</th>
                </tr>
                </thead>

                <tbody className="table-body">
                {users.map((item: any, index) => {
                    return (
                        <tr key={index} className="table-row hover-grey">
                            <td className="td td-3">
                                <a href={`/user/${item.id}/update`}>
                                    {item.name} {item.surname}
                                </a>
                            </td>
                            <td className="td td-5">{item.username}</td>
                            <td className="td td-5">{item.email}</td>
                            <td className="td td-5">{item.dob}</td>
                            <td className="td td-5">{item.fiscal_code}</td>
                            <td className="td td-5">{item.indt}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <nav aria-label='Page navigation'>
                <ul className='pagination pull-right'>
                    <li className={pagination.page === 1 ? 'page-item disabled' : 'page-item '}>
                        <a className='page-link' href='#' aria-label='Previous'>
                            <span aria-hidden='true'>&laquo;</span>
                            <span className='sr-only'>Previous</span>
                        </a>
                    </li>
                    {getPagination()}
                    <li className={pagination.page === pagination.total ? 'page-item disabled' : 'page-item '}>
                        <a className='page-link' href='#' aria-label='Next'>
                            <span aria-hidden='true'>&raquo;</span>
                            <span className='sr-only'>Next</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </Fragment>

}
