import React, {useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {uploadSubscriptionReceipt} from '../../services/course.service';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCloudArrowUp, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {SubscriptionModel} from "../../@types/model";
import PayPalButton from "./paypal";

interface PaymentFormInterface {
    id: number;
    subscription: SubscriptionModel
    onSuccess: (s: SubscriptionModel) => void;
}

function PaymentForm(props: PaymentFormInterface) {

    const {id, subscription, onSuccess} = props;

    const [loading, setLoading] = useState(false)

    const onImageSelected = (thumbnailImageEvent) => {
        setLoading(true)
        const fileRef: any = thumbnailImageEvent.target.files[0];
        if (fileRef) {
            let formData = new FormData();
            formData.append('file', fileRef);
            uploadSubscriptionReceipt(id, formData)
                .then((response) => {
                    onSuccess(response.data.data)
                    setLoading(false)
                })
                .catch(() => {
                    setLoading(false)
                })
        }
    }

    return <div className='well'>
        <div className='alert alert-success text-center'>
            Complimenti la registrazione è andata a buon fine
        </div>
        <div className='mt-4'>
            <h3 className='text-center'>Paga con paypal</h3>
            <PayPalButton
                subscription={subscription}
                onSuccess={onSuccess}
            />
        </div>
        <div className='receipt-form text-center mt-4'>
            <h3>Pagamento tramite bonifico</h3>
            <div className='mt-2'>
                Modalità di pagamento: addebito su c/c 98209117<br/>
                Intestato a APSI Associazione Professioni Sanitarie Italiana<br/>
                Acceso presso Poste Italiane<br/>
                IBAN: IT91B0760116000000098209117<br/>
            </div>
            <div className='my-4'>
                <small className='alert alert-warning'>
                    Allega la copia del pagamento per poter partecipare all'evento<br/>
                </small>
            </div>
            <div className='my-2'>
                <label className='btn btn-primary' htmlFor='receipt'>
                    {loading ?
                        <FontAwesomeIcon icon={faSpinner} size='lg' className='mx-2 fa-spin'/>
                        :
                        <FontAwesomeIcon icon={faCloudArrowUp} size='lg' className='mx-2'/>
                    }
                    Seleziona il file
                </label>
                <input
                    type='file'
                    id='receipt'
                    className='input'
                    hidden
                    onChange={onImageSelected}
                />
            </div>
        </div>
    </div>
}

export default PaymentForm;
