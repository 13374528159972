import React from "react";

import "./style.css";
import {Card, CardBody, CardTitle, Col} from "reactstrap";
import {CourseModel} from "../../../@types/model";


export default function CourseItem({course}: { course: CourseModel }) {
    return <Col sm="3" className="card-col course my-3">
        <Card
            style={{
                width: "18rem",
            }}
        >
            <a href={`${process.env.REACT_APP_APP_URL}/iscrizione/corso/${course.code}`} target="_parent">
                <div className="img-container">
                    <img alt="Sample" src={course.thumbnail ?? 'https://picsum.photos/300/200'}/>
                    <div className="img-container-inner">
                        <span>{course.abstract}</span>
                    </div>
                </div>
                <CardBody>
                    <CardTitle tag="h5">{course.name}</CardTitle>
                    <hr/>
                    <div className="d-flex justify-content-between">
                        <div>Date:</div>
                        <div>{course.date_start} al {course.date_end}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div>Prezzo:</div>
                        <div>{(parseFloat(course.price) > 0) ? course.price + '€' : 'Gratuito'}</div>
                    </div>
                </CardBody>
            </a>
        </Card>
    </Col>
}
