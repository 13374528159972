import React, {Fragment, useEffect, useState} from "react";
import {getCourses} from "../../../services/course.service";

import "./style.css";
import CourseItem from "./single";
import {CourseModel} from "../../../@types/model";
import {useSearchParams} from "react-router-dom";
import {Row} from "reactstrap";

export default function CoursesCatalog() {
    const [searchParams] = useSearchParams();

    const [courses, setCourses] = useState<CourseModel[]>([]);

    useEffect(() => {
        getCourses({
            active: 1,
            category: searchParams.get("category"),
            status: searchParams.get("status"),
            price: searchParams.get("price"),
            formation: searchParams.get("formation"),
            subject: searchParams.get("subject"),
            starred: searchParams.get("starred"),
            registration: searchParams.get("registration"),
        }).then((response: any) => setCourses(response.data.data));
    }, [searchParams]);

    return (
        <Fragment>
            <Row>
                {courses.map((c) => (
                    <CourseItem key={c.id} course={c}/>
                ))}
            </Row>
        </Fragment>
    );
}
