import React, {useState} from "react";
import ReactDOM from "react-dom"
import {Approve, CreateOrder} from "../../services/paypal.service";
import {SubscriptionModel} from "../../@types/model";

// @ts-ignore
const PayPalButtonDriver = paypal.Buttons.driver("react", {React, ReactDOM});

interface PayPalButtonProps {
    subscription: SubscriptionModel
    onSuccess: (s: SubscriptionModel) => void;
}

export default function PayPalButton(props: PayPalButtonProps) {

    const [orderId, setOrderId] = useState<null | string>(null);

    const createOrder = async (data, actions) => {
        const response = await CreateOrder({
            userId: props.subscription.id, courseId: props.subscription.course_id
        })
        setOrderId(response.data.data.id)
        return response.data.data.id
    };

    const onApprove = () => {
        if (!orderId) return
        Approve(orderId)
            .then((response) => props.onSuccess(response.data.data))
    };

    return (
        <PayPalButtonDriver
            createOrder={createOrder}
            onApprove={onApprove}
        />
    );
}
      