import React, {useCallback, useState} from "react";
import "./style.css";
import {useDropzone} from "react-dropzone";

interface DropzoneProps {
    file?: any;
    setFile: any;
}

export const ThumbnailImage = (props: DropzoneProps) => {
    const {setFile} = props;
    const [imageUrl, setImageUrl] = useState<string>(props.file);

    const onDrop = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.addEventListener("load", () => {
                setFile(file);
                setImageUrl(URL.createObjectURL(file));
            });
        },
        [setFile]
    );

    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
    });

    return (
        <div className="dropzone-container">
            <div className="dropzone-title"> Immagine in evidenza</div>
            <div className="dropzone-image-container" {...getRootProps()}>
                <input {...getInputProps()} />
                {imageUrl ? (
                    <img className="dropzone-image" alt="" src={imageUrl}/>
                ) : (
                    <div className="dropzone-image-placeholder">
                        Trascina qui l'immagine oppure fai click per caricarla
                    </div>
                )}
            </div>
        </div>
    );
};
