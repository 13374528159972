import React, {FC} from 'react';
import {WithContext as ReactTags} from 'react-tag-input';
import {HOME_CATEGORIES} from "../../constants/subscription";
import {CategoryTagsProps} from "../../@types/inputs";
import './tags.css';


export const CategoriesTags: FC<CategoryTagsProps> = ({title, onChange, tags}) => {

    const suggestions = Object.keys(HOME_CATEGORIES)
        .map(p => {
            return {
                id: p,
                text: HOME_CATEGORIES[p]
            }
        })

    const handleDelete = i => {
        onChange(tags.filter((tag, index) => index !== i))
    };

    const handleAddition = (tag: any) => {
        onChange([...tags, tag]);
    };

    return (
        <div className="position-relative form-group">
            <label>{title}</label>
            <div>
                <ReactTags
                    className={"form-control"}
                    tags={tags}
                    delimiter={[]}
                    suggestions={suggestions}
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    handleInputChange={() => null}
                    inputFieldPosition="inline"
                    autocomplete={1}
                />
            </div>
        </div>
    );
};