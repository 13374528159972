import React, {Fragment, useEffect, useState} from "react";
import "./style.css";
import {createNewTeacher, uploadImage} from "../../../services/teacher.service";
import {Bounce, toast} from "react-toastify";
import {DateInput, FileInput, Input, Select} from "../../../components/Inputs";
import {DISCIPLINE, EMPLOYEE, PROFESSION} from "../../../constants/subscription";
import {useNavigate} from "react-router-dom";

const TeacherCreate = () => {

    const navigate = useNavigate();

    const [cognome, setCognome] = useState("");
    const [nome, setNome] = useState("");
    const [cv, setCv] = useState("");
    const [identityCard, setIdentityCard] = useState("");
    const [dataDiNascita, setDataDiNascita] = useState("");
    const [luogoDiNascita, setLuogoDiNascita] = useState("");
    const [provinciaDiNascita, setProvinciaDiNascita] = useState("");
    const [indirizzo, setIndirizzo] = useState("");
    const [cap, setCap] = useState("");
    const [citta, setCitta] = useState("");
    const [provincia, setProvincia] = useState("");
    const [codiceFiscale, setCodiceFiscale] = useState("");
    const [postaElettronica, setPostaElettronica] = useState("");
    const [profSanitaria, setProfSanitaria] = useState(0);
    const [disciplinaSanitaria, setDisciplinaSanitaria] = useState(profSanitaria);
    const [liberoProf, setLiberoProf] = useState("");
    const [enteDiAppartenenza, setEnteDiAppartenenza] = useState("");

    const [listOfDiscipline, setListOfDiscipline] = useState<string[]>([]);

    const [errors, setErrors] = useState({});
    const [confirmMessage, setConfirmMessage] = useState("");

    useEffect(() => {
        setListOfDiscipline(DISCIPLINE[profSanitaria] ?? [])
        setDisciplinaSanitaria(profSanitaria)
    }, [profSanitaria])

    function onFormValidate() {
        let errors = {}
        if (!nome) {
            errors['name'] = "nome non può essere vuoto";
        }
        if (!cognome) {
            errors['surname'] = "cognome non può essere vuoto";
        }
        if (!codiceFiscale) {
            errors['fiscal_code'] = "codice fiscale non può essere vuoto";
        }
        if (!postaElettronica) {
            errors['email'] = "email non può essere vuoto";
        }
        if (!dataDiNascita) {
            errors['dob'] = "data non può essere vuoto";
        }
        if (!luogoDiNascita) {
            errors['lob'] = "luogo di nascita non può essere vuoto";
        }
        if (!provinciaDiNascita) {
            errors['pob'] = "campo non valido"
        }
        if (!indirizzo) {
            errors['address'] = "indirizzo non può essere vuoto"
        }
        if (!cap) {
            errors['city_code'] = "cap non valido"
        }
        if (!citta) {
            errors['city'] = "citta non può essere vuoto"
        }
        if (!provincia) {
            errors['province'] = "campo non valido"
        }
        if (!profSanitaria) {
            errors['profession'] = "prof sanitaria non può essere vuoto"
        }
        if (!disciplinaSanitaria) {
            errors['discipline'] = "disciplina sanitaria non può essere vuoto"
        }
        if (!liberoProf) {
            errors['employee'] = "occupazione non può essere vuoto"
        }
        if (!cv) {
            errors['cv'] = "curriculum non può essere vuoto"
        }
        if (!identityCard) {
            errors['identity_card'] = "carta d'identità non può essere vuoto"
        }
        return errors
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const errors = onFormValidate()
        setErrors(errors)
        if (Object.keys(errors).length) {
            setTimeout(() => setErrors({}), 10000)
            return
        }
        createNewTeacher({
            name: nome,
            cv: cv,
            identity_card: identityCard,
            surname: cognome,
            dob: dataDiNascita,
            lob: luogoDiNascita,
            pob: provinciaDiNascita,
            address: indirizzo,
            city_code: cap,
            city: citta,
            province: provincia,
            fiscal_code: codiceFiscale,
            email: postaElettronica,
            profession: profSanitaria,
            discipline: disciplinaSanitaria,
            employee: liberoProf,
            organization: enteDiAppartenenza
        }).then(() => {
            const confirmMessage = "Complimenti la tua iscrizione è stata presa in carico. Verrai contattato da un amministratore nel minor tempo possibile."
            showNotify(confirmMessage)
            setConfirmMessage(confirmMessage)
            setTimeout(() => navigate("/"), 5000)
        }).catch((response) => {
            setErrors(response.data.data)
            setTimeout(() => setErrors({}), 10000)
            showNotifyError(response.data.error.message)
        })
    };

    const showNotify = (message) => toast(message, {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'bottom-center',
        type: 'success'
    });

    const showNotifyError = (error) => toast(error, {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'bottom-center',
        type: 'error'
    });

    const cvHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return
        let formData = new FormData();
        formData.append("file", event.target.files[0]);
        uploadImage(formData).then((response: any) => {
            setCv(response.data.data.filename)
        })
    };

    const icHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return
        let formData = new FormData();
        formData.append("file", event.target.files[0]);
        uploadImage(formData).then((response: any) => {
            setIdentityCard(response.data.data.filename)
        })
    };

    return <Fragment>
        <div className='logo-content'>
            <div className="app-logo"/>
        </div>

        <div className='container'>
            <form onSubmit={onSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <Input
                            inputValue={cognome}
                            setInputValue={setCognome}
                            title={"Cognome"}
                            type={"text"}
                            placeholder={"scrivi qui..."}
                            errors={errors['surname']}
                        />
                    </div>
                    <div className="col-md-6">
                        <Input
                            inputValue={nome}
                            setInputValue={setNome}
                            title={"Nome"}
                            type={"text"}
                            placeholder={"scrivi qui..."}
                            errors={errors['name']}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <DateInput
                            inputValue={dataDiNascita}
                            setInputValue={setDataDiNascita}
                            title={"Data di nascita"}
                            type={"date"}
                            errors={errors['dob']}
                        />

                    </div>
                    <div className="col-md-7">
                        <Input
                            inputValue={luogoDiNascita}
                            setInputValue={setLuogoDiNascita}
                            title={"Luogo"}
                            type={"text"}
                            placeholder={"scrivi qui..."}
                            errors={errors['lob']}
                        />
                    </div>
                    <div className="col-md-2">
                        <Input
                            inputValue={provinciaDiNascita}
                            setInputValue={setProvinciaDiNascita}
                            title={"Prov"}
                            type={"text"}
                            maxLength={2}
                            placeholder={"scrivi qui..."}
                            errors={errors['pob']}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <Input
                            inputValue={indirizzo}
                            setInputValue={setIndirizzo}
                            title={"Indirizzo"}
                            type={"text"}
                            placeholder={"scrivi qui..."}
                            errors={errors['address']}
                        />
                    </div>
                    <div className="col-md-2">
                        <Input
                            inputValue={cap}
                            setInputValue={setCap}
                            title={"CAP"}
                            type={"text"}
                            placeholder={"00000"}
                            maxLength={5}
                            errors={errors['city_code']}
                        />
                    </div>
                    <div className="col-md-8">
                        <Input
                            inputValue={citta}
                            setInputValue={setCitta}
                            title={"Città"}
                            type={"text"}
                            placeholder={"scrivi qui..."}
                            errors={errors['city']}
                        />
                    </div>
                    <div className="col-md-2">
                        <Input
                            inputValue={provincia}
                            setInputValue={setProvincia}
                            title={"Prov"}
                            type={"text"}
                            placeholder={"scrivi qui..."}
                            maxLength={2}
                            errors={errors['province']}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <Input
                            inputValue={codiceFiscale}
                            setInputValue={setCodiceFiscale}
                            title={"Codice Fiscale"}
                            type={"text"}
                            placeholder={"scrivi qui..."}
                            errors={errors['fiscal_code']}
                        />
                    </div>
                    <div className="col-md-6">
                        <Input
                            inputValue={postaElettronica}
                            setInputValue={setPostaElettronica}
                            title={"Indirizzo posta elettronica"}
                            type={"text"}
                            placeholder={"scrivi qui..."}
                            errors={errors['email']}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Select
                            inputValue={profSanitaria}
                            setInputValue={setProfSanitaria}
                            title={"Professione sanitaria"}
                            list={PROFESSION}
                            errors={errors['profession']}
                        />
                    </div>
                    <div className="col-md-6">
                        <Select
                            inputValue={disciplinaSanitaria}
                            setInputValue={setDisciplinaSanitaria}
                            title={"Disciplina sanitaria"}
                            list={listOfDiscipline}
                            errors={errors['disciplie']}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Select
                            inputValue={liberoProf}
                            setInputValue={setLiberoProf}
                            title={"Occupazione"}
                            list={EMPLOYEE}
                            errors={errors['employee']}
                        />
                    </div>
                    <div className="col-md-6">
                        <Input
                            inputValue={enteDiAppartenenza}
                            setInputValue={setEnteDiAppartenenza}
                            title={"Ente di appartenenza"}
                            type={"text"}
                            placeholder={"scrivi qui..."}
                            errors={errors['organization']}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <FileInput
                            setInputValue={cvHandler}
                            title={"CurriculumVitae"}
                            errors={errors['cv']}
                        />
                    </div>
                    <div className="col-md-6">
                        <FileInput
                            setInputValue={icHandler}
                            title={"Documento"}
                            errors={errors['identity_card']}
                        />
                    </div>
                </div>

                <div className="mt-2">
                    <button className="btn btn-info w-100">
                        Invia
                    </button>

                    {confirmMessage !== '' ? <div className="alert alert-success">{confirmMessage}</div> : null}

                </div>
            </form>
        </div>
    </Fragment>
};

export default TeacherCreate;
