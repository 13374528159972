import React, {useState} from "react";
import "./style.css";

import {resetPassword} from "../../../services/auth.service";

const ResetPasswordForm = ({onBackToLogin}) => {

    const [username, setUsername] = useState("");
    const [loading, setLoading] = useState(false);

    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };

    const handleForm = (e) => {
        e.preventDefault();
        setLoading(true);
        resetPassword(username)
            .then((response) => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    return <form onSubmit={handleForm}>
        <div className="form-group">
            <label htmlFor="username" className="form-control-label">
                USERNAME
            </label>
            <input
                type="text"
                className="form-control"
                name="username"
                value={username}
                onChange={onChangeUsername}
            />
        </div>

        <div className="row">
            <div className="col-6">
                <button
                    className="btn btn-outline-primary"
                    onClick={onBackToLogin}
                >
                    <span>Ritorna al Login</span>
                </button>
            </div>
            <div className="col-6">
                <button
                    className="btn btn-outline-primary"
                    disabled={loading}
                >
                    {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Recupera</span>
                </button>
            </div>

            <div className="clearfix"/>
        </div>
    </form>
};

export default ResetPasswordForm;
