import {UserModel} from "../@types/model";

export default function authHeader() {
    const user: UserModel = JSON.parse(localStorage.getItem('user') || "{}");

    if (user && user.auth_key) {
        // for Node.js Express back-end
        return {'x-access-token': user.auth_key};
    } else {
        return {};
    }
}