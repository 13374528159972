import axios from "axios";
import authHeader from "./auth-header";

export const getTeachers = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/teacher`, {headers: authHeader()});
};

export const createNewTeacher = async (body: TeacherRegistrationForm) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/teacher/create`, body);
};

export const editTeacher = async (body: object, id: string) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/teacher/${id}/update`, body, {headers: authHeader()});
};

export const teacherSubscribeToCourse = async (courseId: number, body: TeacherSubscriptionForm) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/course/${courseId}/teacher-subscribe`, body, {headers: authHeader()});
};

export const getPDFContent = async (id: number, pdfName: string) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/pdf/${id}/load?template=${pdfName}`, {headers: authHeader()});
};

export const uploadPDF = async (id: string, type: string, body: object) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/pdf/upload?courseId=${id}&type=${type}`, body, {headers: authHeader()});
};

export const uploadImage = async (body: object) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/teacher/upload`, body);
}

export const approve = async (id: number, confirm: boolean) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/teacher/${id}/approve?status=${confirm ? 1 : 0}`, {headers: authHeader()});
};