import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import "./style.css";
import {getCourseByCode, updateSubscription} from "../../../services/course.service";
import {DISCIPLINE, EMPLOYEE, PROFESSION} from "../../../constants/subscription";
import {Bounce, toast} from "react-toastify";
import {Input} from "reactstrap";
import {DateInput, Select} from "../../../components/Inputs";

const CourseSubscriptionUpdate = () => {

    const [isLoading, setIsLoading] = useState(false);

    const {id} = useParams<{ id: string }>();

    useEffect(() => {
        setIsLoading(true);
        getCourseByCode(parseInt(id ?? "0")).then((response: any) => {
            setIsLoading(false);
        });
    }, [id]);

    const [cognome, setCognome] = useState("");
    const [nome, setNome] = useState("");
    const [dataDiNascita, setDataDiNascita] = useState("");
    const [luogoDiNascita, setLuogoDiNascita] = useState("");
    const [provinciaDiNascita, setProvinciaDiNascita] = useState("");
    const [indirizzo, setIndirizzo] = useState("");
    const [cap, setCap] = useState("");
    const [citta, setCitta] = useState("");
    const [provincia, setProvincia] = useState("");
    const [codiceFiscale, setCodiceFiscale] = useState("");
    const [postaElettronica, setPostaElettronica] = useState("");
    const [profSanitaria, setProfSanitaria] = useState(0);
    const [disciplinaSanitaria, setDisciplinaSanitaria] = useState(profSanitaria);
    const [liberoProf, setLiberoProf] = useState("");
    const [enteDiAppartenenza, setEnteDiAppartenenza] = useState("");
    const [paroladordine, setParoladordine] = useState("");
    const [note, setNote] = useState("");

    const [listOfDiscipline, setListOfDiscipline] = useState<string[]>([]);
    const [confirmMessage, setConfirmMessage] = useState<string>("");

    const [errors, setErrors] = useState({});

    useEffect(() => {
        setListOfDiscipline(DISCIPLINE[profSanitaria] ?? [])
        setDisciplinaSanitaria(profSanitaria)
    }, [profSanitaria])

    function onFormValidate() {
        let errors = {}
        if (!nome) {
            errors['name'] = "nome non può essere vuoto";
        }
        if (!cognome) {
            errors['surname'] = "cognome non può essere vuoto";
        }
        if (!codiceFiscale) {
            errors['fiscal_code'] = "codice fiscale non può essere vuoto";
        }
        if (!postaElettronica) {
            errors['email'] = "email non può essere vuoto";
        }
        if (!dataDiNascita) {
            errors['dob'] = "data non può essere vuoto";
        }
        if (!luogoDiNascita) {
            errors['lob'] = "luogo di nascita non può essere vuoto";
        }
        if (!provinciaDiNascita) {
            errors['pob'] = "campo non valido"
        }
        if (!indirizzo) {
            errors['address'] = "indirizzo non può essere vuoto"
        }
        if (!cap) {
            errors['city_code'] = "cap non valido"
        }
        if (!citta) {
            errors['city'] = "citta non può essere vuoto"
        }
        if (!provincia) {
            errors['province'] = "campo non valido"
        }
        if (!profSanitaria) {
            errors['profession'] = "prof sanitaria non può essere vuoto"
        }
        if (!disciplinaSanitaria) {
            errors['discipline'] = "disciplina sanitaria non può essere vuoto"
        }
        if (!liberoProf) {
            errors['employee'] = "occupazione non può essere vuoto"
        }
        return errors
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const errors = onFormValidate()
        setErrors(errors)
        if (Object.keys(errors).length) {
            return
        }
        updateSubscription(0, {
            surname: cognome,
            name: nome,
            dob: dataDiNascita,
            lob: luogoDiNascita,
            pob: provinciaDiNascita,
            address: indirizzo,
            city_code: cap,
            city: citta,
            province: provincia,
            fiscal_code: codiceFiscale,
            email: postaElettronica,
            profession: profSanitaria,
            discipline: disciplinaSanitaria,
            employee: liberoProf,
            organization: enteDiAppartenenza,
            password: paroladordine.toUpperCase(),
            note: note,
        })
            .then(() => {
                const message = "Complimenti la tua iscrizione è andata a buon fine"
                showNotify(message);
                setConfirmMessage(message)
                setNome("");
                setCognome("");
                setDataDiNascita("");
                setLuogoDiNascita("");
                setProvinciaDiNascita("");
                setIndirizzo("");
                setCap("");
                setCitta("");
                setProvincia("");
                setCodiceFiscale("");
                setPostaElettronica("");
                setProfSanitaria(0);
                setDisciplinaSanitaria(profSanitaria);
                setLiberoProf("");
                setEnteDiAppartenenza("");
                setParoladordine("");
                setNote("");
                setTimeout(() => setConfirmMessage(""), 5000)
            })
            .catch(({response}) => {
                setErrors(response.data.data)
                alert(response.data.error.message)
            });
    };

    const showNotify = (message: string) =>
        toast(message, {
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-center",
            type: "success",
        });

    return <form className="registration-form" onSubmit={onSubmit}>
        <div className="row">
            <div className="col-md-6 registration-field">
                <Input
                    inputValue={cognome}
                    setInputValue={setCognome}
                    title={"Cognome"}
                    type={"text"}
                    placeholder={"scrivi qui..."}
                    errors={errors['surname']}
                />
            </div>
            <div className="col-md-6 registration-field">
                <Input
                    inputValue={nome}
                    setInputValue={setNome}
                    title={"Nome"}
                    type={"text"}
                    placeholder={"scrivi qui..."}
                    errors={errors['name']}
                />

            </div>
        </div>
        <div className="row">
            <div className="col-md-3 registration-field">
                <DateInput
                    inputValue={dataDiNascita}
                    setInputValue={setDataDiNascita}
                    title={"Data di nascita"}
                    type={"date"}
                    errors={errors['dob']}
                />

            </div>
            <div className="col-md-7 registration-field">
                <Input
                    inputValue={luogoDiNascita}
                    setInputValue={setLuogoDiNascita}
                    title={"Luogo"}
                    type={"text"}
                    placeholder={"scrivi qui..."}
                    errors={errors['lob']}
                />
            </div>
            <div className="col-md-2 registration-field">
                <Input
                    inputValue={provinciaDiNascita}
                    setInputValue={setProvinciaDiNascita}
                    title={"Prov"}
                    type={"text"}
                    maxLength={2}
                    placeholder={"scrivi qui..."}
                    errors={errors['pob']}
                />
            </div>
        </div>

        <div className="row">
            <div className="col-md-12 registration-field">
                <Input
                    inputValue={indirizzo}
                    setInputValue={setIndirizzo}
                    title={"Indirizzo"}
                    type={"text"}
                    placeholder={"scrivi qui..."}
                    errors={errors['address']}
                />
            </div>
            <div className="col-md-2 registration-field">
                <Input
                    inputValue={cap}
                    setInputValue={setCap}
                    title={"CAP"}
                    type={"text"}
                    placeholder={"00000"}
                    maxLength={5}
                    errors={errors['city_code']}
                />
            </div>
            <div className="col-md-8 registration-field">
                <Input
                    inputValue={citta}
                    setInputValue={setCitta}
                    title={"Città"}
                    type={"text"}
                    placeholder={"scrivi qui..."}
                    errors={errors['city']}
                />
            </div>
            <div className="col-md-2 registration-field">
                <Input
                    inputValue={provincia}
                    setInputValue={setProvincia}
                    title={"Prov"}
                    type={"text"}
                    placeholder={"scrivi qui..."}
                    maxLength={2}
                    errors={errors['province']}
                />
            </div>
        </div>

        <div className="row">
            <div className="col-md-6 registration-field">
                <Input
                    inputValue={codiceFiscale}
                    setInputValue={setCodiceFiscale}
                    title={"Codice Fiscale"}
                    type={"text"}
                    placeholder={"scrivi qui..."}
                    errors={errors['fiscal_code']}
                />

            </div>
            <div className="col-md-6 registration-field">
                <Input
                    inputValue={postaElettronica}
                    setInputValue={setPostaElettronica}
                    title={"Indirizzo posta elettronica"}
                    type={"text"}
                    placeholder={"scrivi qui..."}
                    errors={errors['email']}
                />

            </div>
        </div>
        <div className="row">
            <div className="col-md-6 registration-field">
                <Select
                    inputValue={profSanitaria}
                    setInputValue={setProfSanitaria}
                    title={"Professione sanitaria"}
                    list={PROFESSION}
                    errors={errors['profession']}
                />
            </div>
            <div className="col-md-6 registration-field">
                <Select
                    inputValue={disciplinaSanitaria}
                    setInputValue={setDisciplinaSanitaria}
                    title={"Disciplina sanitaria"}
                    list={listOfDiscipline}
                    errors={errors['disciplie']}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-md-6 registration-field">
                <Select
                    inputValue={liberoProf}
                    setInputValue={setLiberoProf}
                    title={"Occupazione"}
                    list={EMPLOYEE}
                    errors={errors['employee']}
                />
            </div>
            <div className="col-md-6 registration-field">
                <Input
                    inputValue={enteDiAppartenenza}
                    setInputValue={setEnteDiAppartenenza}
                    title={"Ente di appartenenza"}
                    type={"text"}
                    placeholder={"scrivi qui..."}
                    errors={errors['organization']}
                />
            </div>
        </div>

        <div className="row">
            <div className="col-12 input-container form-group">
                <label> Note</label>
                <textarea
                    value={note}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                        setNote(e.target.value)
                    }
                    className="col-12"
                    rows={6}
                />
            </div>
        </div>

        {confirmMessage !== '' ? <div className="alert alert-success">{confirmMessage}</div> : null}

        <div>
            <button className="btn btn-info w-100">
                Invia
            </button>
        </div>
    </form>
};

export default CourseSubscriptionUpdate;
