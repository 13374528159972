import React from "react";

import "./style.css";
import {CourseModel} from "../../../@types/model";
import {PROFESSION} from "../../../constants/subscription";
import {formatPrice} from "../../../constants/course";


export default function CourseRow({course}: { course: CourseModel }) {
    return <div className="course-item">
        <div className="course-wrap-thumbnail">
            <div className="course-thumbnail">
                <a href="https://www.professionisanitarie.com/courses/software-development/">
                    <div className="thumbnail-preview">
                        <div className="thumbnail">
                            <div className="centered">
                                {course.thumbnail &&
                                    <img width="500" height="300"
                                         src={course.thumbnail}
                                         className="attachment-500x300 size-500x300 wp-post-image"
                                         alt="Software Development" loading="lazy"
                                         title="Software Development"/>}
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <div className="course-content">
            <div className="course-categories">
                <a href="https://www.professionisanitarie.com/course-category/computer-science/" rel="tag">
                    {PROFESSION[1]}
                </a>
            </div>

            <span className="course-instructor">
                <a href="">
                    <span></span>
                </a>
            </span>

            <a href="https://www.professionisanitarie.com/courses/software-development/"
               className="course-permalink">
                <h3 className="course-title">{course.name}</h3>
            </a>

            <div className="course-wrap-meta">
                <div className="meta-item meta-item-duration">10 weeks</div>


                <div className="meta-item meta-item-level">All levels</div>


                <div className="meta-item meta-item-lesson"><span className="meta-number">47 lessons</span></div>


                <div className="meta-item meta-item-quiz"><span className="meta-number">4 quizzes</span></div>


                <div className="meta-item meta-item-student"><span className="meta-number">0 students</span></div>

            </div>
            <div className="separator"></div>
            <div className="course-info">

                <div className="course-excerpt" dangerouslySetInnerHTML={{__html: course.description || ''}}/>

                <div className="clearfix"></div>
                <div className="course-footer">
                    <div className="course-price">
                        <span className="price">{formatPrice(parseFloat(course?.price || "0"))}</span></div>
                </div>
                <div className="course-readmore">
                    <a href="https://www.professionisanitarie.com/courses/software-development/">View More</a>
                </div>

            </div>
        </div>
    </div>
}
